export enum BusinessEntityFieldType
{
    PrimaryKey = "pk",
    ForeignKey = "fk",
    BusinessEntityArray = "business_entity_array",
    ReverseForeignKey = "reverse_fk",
    VarChar = "varchar",
    Text = "text",
    Integer = "int",
    Decimal = "decimal",
    Boolean = "tinyint",
    Date = "date",
    Time = "time",
    DateTime = "datetime",
    TimeStamp = "timestamp",
    Json = "json",
    File = "file",
    FileList = "file_list",
    Custom = "custom",
}