import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  ExpectedErrorType: any;
  GenericScalar: any;
  JSONString: any;
  Time: any;
  Upload: any;
};

export type AppSetting = Node & {
  __typename?: 'AppSetting';
  id: Scalars['ID'];
  /** Is this settings value publicly available (eg: GraphQL without authentication) */
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  /** The setting value. Only public values are readable by everyone. */
  value?: Maybe<Scalars['JSONString']>;
};

export type AppSettingFastConnection = {
  __typename?: 'AppSettingFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<AppSettingFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AppSettingFastEdge = {
  __typename?: 'AppSettingFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AppSetting>;
};

export type AppSettingInput = {
  id?: InputMaybe<Scalars['Int']>;
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  value: Scalars['JSONString'];
};

/**
 * Archive account and revoke refresh tokens.
 *
 * User must be verified and confirm password.
 */
export type ArchiveAccount = {
  __typename?: 'ArchiveAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  success?: Maybe<Scalars['Boolean']>;
};

/** Create a new pixie with images */
export type CreatePixieWithImages = {
  __typename?: 'CreatePixieWithImages';
  /** The created pixie */
  pixie?: Maybe<Pixie>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Delete account permanently or make `user.is_active=False`.
 *
 * The behavior is defined on settings.
 * Anyway user refresh tokens are revoked.
 *
 * User must be verified and confirm password.
 */
export type DeleteAccount = {
  __typename?: 'DeleteAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteAppSetting = {
  __typename?: 'DeleteAppSetting';
  object?: Maybe<AppSetting>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteFacebookPage = {
  __typename?: 'DeleteFacebookPage';
  object?: Maybe<FacebookPage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteFacebookPost = {
  __typename?: 'DeleteFacebookPost';
  object?: Maybe<FacebookPost>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteFacebookUserProfile = {
  __typename?: 'DeleteFacebookUserProfile';
  object?: Maybe<FacebookUserProfile>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  object?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePageFeedPost = {
  __typename?: 'DeletePageFeedPost';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePermission = {
  __typename?: 'DeletePermission';
  object?: Maybe<Permission>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePixie = {
  __typename?: 'DeletePixie';
  object?: Maybe<Pixie>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePixieImage = {
  __typename?: 'DeletePixieImage';
  object?: Maybe<PixieImage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePixieImageTranslation = {
  __typename?: 'DeletePixieImageTranslation';
  object?: Maybe<PixieImageTranslation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeletePixieTranslation = {
  __typename?: 'DeletePixieTranslation';
  object?: Maybe<PixieTranslation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteScheduleLanguage = {
  __typename?: 'DeleteScheduleLanguage';
  object?: Maybe<ScheduleLanguage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteSchedulePhase = {
  __typename?: 'DeleteSchedulePhase';
  object?: Maybe<SchedulePhase>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteUser = {
  __typename?: 'DeleteUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type FacebookMetaTagsType = {
  __typename?: 'FacebookMetaTagsType';
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['String']>;
  ogTitle?: Maybe<Scalars['String']>;
  ogUrl?: Maybe<Scalars['String']>;
};

/** Represents a Facebook page managed by the scheduler. */
export type FacebookPage = Node & {
  __typename?: 'FacebookPage';
  accessToken: Scalars['String'];
  facebookId: Scalars['String'];
  facebookPosts: FacebookPostConnection;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  language: ScheduleLanguage;
  name: Scalars['String'];
  /** The name of the page. */
  nameDetailed?: Maybe<Scalars['String']>;
  phases: SchedulePhaseConnection;
  /** The phases of the schedule. */
  phasesList?: Maybe<Array<Maybe<SchedulePhase>>>;
  profile: FacebookUserProfile;
  timezone: Scalars['Int'];
  trackingCode: Scalars['String'];
};


/** Represents a Facebook page managed by the scheduler. */
export type FacebookPageFacebookPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Represents a Facebook page managed by the scheduler. */
export type FacebookPagePhasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FacebookPageConnection = {
  __typename?: 'FacebookPageConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacebookPageEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FacebookPage` and its cursor. */
export type FacebookPageEdge = {
  __typename?: 'FacebookPageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FacebookPage>;
};

export type FacebookPageFastConnection = {
  __typename?: 'FacebookPageFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<FacebookPageFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FacebookPageFastEdge = {
  __typename?: 'FacebookPageFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FacebookPage>;
};

export type FacebookPageInput = {
  facebookId: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  languageCode: Scalars['String'];
  name: Scalars['String'];
  profileId: Scalars['Int'];
  timezone: Scalars['Int'];
  trackingCode?: InputMaybe<Scalars['String']>;
};

/** Represents a Facebook post that is scheduled or already posted. */
export type FacebookPost = Node & {
  __typename?: 'FacebookPost';
  facebookPostId: Scalars['String'];
  id: Scalars['ID'];
  isPosted: Scalars['Boolean'];
  link: Scalars['String'];
  /** If file: relative path to the MEDIA_ROOT */
  mediaFile: Scalars['String'];
  /** The media file url of the post. */
  mediaFileUrl?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  ogTags: Scalars['JSONString'];
  page: FacebookPage;
  phase: SchedulePhase;
  reactionCount: Scalars['Int'];
  scheduleDate: Scalars['Date'];
  scheduleTime: Scalars['Time'];
  shareCount: Scalars['Int'];
  /** The text of the post. */
  text?: Maybe<Scalars['String']>;
  typeOfPost: FacebookPostTypeOfPost;
};

export type FacebookPostConnection = {
  __typename?: 'FacebookPostConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacebookPostEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FacebookPost` and its cursor. */
export type FacebookPostEdge = {
  __typename?: 'FacebookPostEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FacebookPost>;
};

export type FacebookPostFastConnection = {
  __typename?: 'FacebookPostFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<FacebookPostFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FacebookPostFastEdge = {
  __typename?: 'FacebookPostFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FacebookPost>;
};

export type FacebookPostInput = {
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pageId: Scalars['Int'];
  phaseId: Scalars['Int'];
  photo?: InputMaybe<Scalars['Upload']>;
  scheduleDate: Scalars['Date'];
};

/** An enumeration. */
export enum FacebookPostTypeOfPost {
  /** LINK */
  Link = 'LINK',
  /** PHOTO */
  Photo = 'PHOTO',
  /** VIDEO */
  Video = 'VIDEO'
}

/** A Facebook user that is associated with a Facebook App. */
export type FacebookUserProfile = Node & {
  __typename?: 'FacebookUserProfile';
  accessToken?: Maybe<Scalars['String']>;
  appId: Scalars['String'];
  appSecret: Scalars['String'];
  facebookPages: FacebookPageConnection;
  id: Scalars['ID'];
  /** The user associated with this profile. */
  user?: Maybe<UserNode>;
};


/** A Facebook user that is associated with a Facebook App. */
export type FacebookUserProfileFacebookPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FacebookUserProfileConnection = {
  __typename?: 'FacebookUserProfileConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FacebookUserProfileEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FacebookUserProfile` and its cursor. */
export type FacebookUserProfileEdge = {
  __typename?: 'FacebookUserProfileEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<FacebookUserProfile>;
};

export type FacebookUserProfileFastConnection = {
  __typename?: 'FacebookUserProfileFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<FacebookUserProfileFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FacebookUserProfileFastEdge = {
  __typename?: 'FacebookUserProfileFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<FacebookUserProfile>;
};

export type FacebookUserProfileInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  appId: Scalars['String'];
  appSecret: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

/** Group schema. */
export type Group = Node & {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupFastConnection = {
  __typename?: 'GroupFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<GroupFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type GroupFastEdge = {
  __typename?: 'GroupFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Group>;
};

export type GroupInput = {
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** Represents an image on the web. */
export type Image = {
  __typename?: 'Image';
  /** The height of the image file. */
  height?: Maybe<Scalars['Int']>;
  /** The URL of the image file. */
  url?: Maybe<Scalars['String']>;
  /** The width of the image file. */
  width?: Maybe<Scalars['Int']>;
};

export type LoginFacebookUser = {
  __typename?: 'LoginFacebookUser';
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type LoginGoogleUser = {
  __typename?: 'LoginGoogleUser';
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type ManageManyToManyCollectionAppSetting = {
  __typename?: 'ManageManyToManyCollectionAppSetting';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionFacebookPage = {
  __typename?: 'ManageManyToManyCollectionFacebookPage';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionFacebookPost = {
  __typename?: 'ManageManyToManyCollectionFacebookPost';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionFacebookUserProfile = {
  __typename?: 'ManageManyToManyCollectionFacebookUserProfile';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionGroup = {
  __typename?: 'ManageManyToManyCollectionGroup';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionPermission = {
  __typename?: 'ManageManyToManyCollectionPermission';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionPixie = {
  __typename?: 'ManageManyToManyCollectionPixie';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionPixieImage = {
  __typename?: 'ManageManyToManyCollectionPixieImage';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionPixieImageTranslation = {
  __typename?: 'ManageManyToManyCollectionPixieImageTranslation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionPixieTranslation = {
  __typename?: 'ManageManyToManyCollectionPixieTranslation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionScheduleLanguage = {
  __typename?: 'ManageManyToManyCollectionScheduleLanguage';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageManyToManyCollectionSchedulePhase = {
  __typename?: 'ManageManyToManyCollectionSchedulePhase';
  success?: Maybe<Scalars['Boolean']>;
};

/** Manage the many-to-many relationships of an entity and a collection of entities. */
export type ManageManyToManyCollectionUser = {
  __typename?: 'ManageManyToManyCollectionUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  /**
   * Archive account and revoke refresh tokens.
   *
   * User must be verified and confirm password.
   */
  archiveAccount?: Maybe<ArchiveAccount>;
  changePassword?: Maybe<ChangePassword>;
  /** Create a new pixie with images */
  createPixieWithImages?: Maybe<CreatePixieWithImages>;
  /**
   * Delete account permanently or make `user.is_active=False`.
   *
   * The behavior is defined on settings.
   * Anyway user refresh tokens are revoked.
   *
   * User must be verified and confirm password.
   */
  deleteAccount?: Maybe<DeleteAccount>;
  deleteAppSetting?: Maybe<DeleteAppSetting>;
  deleteFacebookPage?: Maybe<DeleteFacebookPage>;
  deleteFacebookPost?: Maybe<DeleteFacebookPost>;
  deleteFacebookUserProfile?: Maybe<DeleteFacebookUserProfile>;
  deleteGroup?: Maybe<DeleteGroup>;
  deletePageFeedPost?: Maybe<DeletePageFeedPost>;
  deletePermission?: Maybe<DeletePermission>;
  deletePixie?: Maybe<DeletePixie>;
  deletePixieImage?: Maybe<DeletePixieImage>;
  deletePixieImageTranslation?: Maybe<DeletePixieImageTranslation>;
  deletePixieTranslation?: Maybe<DeletePixieTranslation>;
  deleteScheduleLanguage?: Maybe<DeleteScheduleLanguage>;
  deleteSchedulePhase?: Maybe<DeleteSchedulePhase>;
  deleteUser?: Maybe<DeleteUser>;
  loginFacebookUser?: Maybe<LoginFacebookUser>;
  loginGoogleUser?: Maybe<LoginGoogleUser>;
  manageManyToManyCollectionAppSetting?: Maybe<ManageManyToManyCollectionAppSetting>;
  manageManyToManyCollectionFacebookPage?: Maybe<ManageManyToManyCollectionFacebookPage>;
  manageManyToManyCollectionFacebookPost?: Maybe<ManageManyToManyCollectionFacebookPost>;
  manageManyToManyCollectionFacebookUserProfile?: Maybe<ManageManyToManyCollectionFacebookUserProfile>;
  manageManyToManyCollectionGroup?: Maybe<ManageManyToManyCollectionGroup>;
  manageManyToManyCollectionPermission?: Maybe<ManageManyToManyCollectionPermission>;
  manageManyToManyCollectionPixie?: Maybe<ManageManyToManyCollectionPixie>;
  manageManyToManyCollectionPixieImage?: Maybe<ManageManyToManyCollectionPixieImage>;
  manageManyToManyCollectionPixieImageTranslation?: Maybe<ManageManyToManyCollectionPixieImageTranslation>;
  manageManyToManyCollectionPixieTranslation?: Maybe<ManageManyToManyCollectionPixieTranslation>;
  manageManyToManyCollectionScheduleLanguage?: Maybe<ManageManyToManyCollectionScheduleLanguage>;
  manageManyToManyCollectionSchedulePhase?: Maybe<ManageManyToManyCollectionSchedulePhase>;
  /** Manage the many-to-many relationships of an entity and a collection of entities. */
  manageManyToManyCollectionUser?: Maybe<ManageManyToManyCollectionUser>;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange?: Maybe<PasswordChange>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset?: Maybe<PasswordReset>;
  /**
   * Set user password - for passwordless registration
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, set
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordSet?: Maybe<PasswordSet>;
  /** Post to a page */
  postToPage?: Maybe<PostToPage>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<RefreshToken>;
  /**
   * Register user with fields defined in the settings.
   *
   * If the email field of the user model is part of the
   * registration fields (default), check if there is
   * no user with that email or as a secondary email.
   *
   * If it exists, it does not register the user,
   * even if the email field is not defined as unique
   * (default of the default django user model).
   *
   * When creating the user, it also creates a `UserStatus`
   * related to that user, making it possible to track
   * if the user is archived, verified and has a secondary
   * email.
   *
   * Send account verification email.
   *
   * If allowed to not verified users login, return token.
   */
  register?: Maybe<Register>;
  /**
   * Remove user secondary email.
   *
   * Require password confirmation.
   */
  removeSecondaryEmail?: Maybe<RemoveSecondaryEmail>;
  /**
   * Sends activation email.
   *
   * It is called resend because theoretically
   * the first activation email was sent when
   * the user registered.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  resendActivationEmail?: Maybe<ResendActivationEmail>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  revokeToken?: Maybe<RevokeToken>;
  saveAppSetting?: Maybe<SaveAppSetting>;
  saveFacebookPage?: Maybe<SaveFacebookPage>;
  saveFacebookPost?: Maybe<SaveFacebookPost>;
  saveFacebookUserProfile?: Maybe<SaveFacebookUserProfile>;
  saveGroup?: Maybe<SaveGroup>;
  /** Save long lived access token and update the pages as well() */
  saveLongLivedAccessTokenAndUpdatePagesForUser?: Maybe<SaveLongLivedAccessTokenAndUpdatePagesForUser>;
  savePermission?: Maybe<SavePermission>;
  savePixie?: Maybe<SavePixie>;
  savePixieImage?: Maybe<SavePixieImage>;
  savePixieImageTranslation?: Maybe<SavePixieImageTranslation>;
  savePixieTranslation?: Maybe<SavePixieTranslation>;
  saveScheduleLanguage?: Maybe<SaveScheduleLanguage>;
  saveSchedulePhase?: Maybe<SaveSchedulePhase>;
  saveUser?: Maybe<SaveUser>;
  /** Schedule a bunch of posts */
  scheduleABunchOfPosts?: Maybe<ScheduleABunchOfPosts>;
  /** Schedule a post */
  schedulePost?: Maybe<SchedulePost>;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * Accepts both primary and secondary email.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmail>;
  /**
   * Send activation to secondary email.
   *
   * User must be verified and confirm password.
   */
  sendSecondaryEmailActivation?: Maybe<SendSecondaryEmailActivation>;
  setPermissionsForGroup?: Maybe<SetPermissionsForGroup>;
  setPermissionsForUser?: Maybe<SetPermissionsForUser>;
  /**
   * Swap between primary and secondary emails.
   *
   * Require password confirmation.
   */
  swapEmails?: Maybe<SwapEmails>;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   *
   * Not verified users can login by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  /**
   * Update user model fields, defined on settings.
   *
   * User must be verified.
   */
  updateAccount?: Maybe<UpdateAccount>;
  /** Update existing slugs */
  updateExistingSlugs?: Maybe<UpdateExistingSlugs>;
  /**
   * Verify user account.
   *
   * Receive the token that was sent by email.
   * If the token is valid, make the user verified
   * by making the `user.status.verified` field true.
   */
  verifyAccount?: Maybe<VerifyAccount>;
  /**
   * Verify user secondary email.
   *
   * Receive the token that was sent by email.
   * User is already verified when using this mutation.
   *
   * If the token is valid, add the secondary email
   * to `user.status.secondary_email` field.
   *
   * Note that until the secondary email is verified,
   * it has not been saved anywhere beyond the token,
   * so it can still be used to create a new account.
   * After being verified, it will no longer be available.
   */
  verifySecondaryEmail?: Maybe<VerifySecondaryEmail>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyToken>;
};


export type MutationsArchiveAccountArgs = {
  password: Scalars['String'];
};


export type MutationsChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationsCreatePixieWithImagesArgs = {
  description?: InputMaybe<Scalars['String']>;
  image: Scalars['Upload'];
  images: Array<InputMaybe<Scalars['Upload']>>;
  language: Scalars['String'];
  title: Scalars['String'];
};


export type MutationsDeleteAccountArgs = {
  password: Scalars['String'];
};


export type MutationsDeleteAppSettingArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteFacebookPageArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteFacebookPostArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteFacebookUserProfileArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteGroupArgs = {
  id: Scalars['Int'];
};


export type MutationsDeletePageFeedPostArgs = {
  feedPostId?: InputMaybe<Scalars['String']>;
  pageId?: InputMaybe<Scalars['Int']>;
};


export type MutationsDeletePermissionArgs = {
  id: Scalars['Int'];
};


export type MutationsDeletePixieArgs = {
  id: Scalars['Int'];
};


export type MutationsDeletePixieImageArgs = {
  id: Scalars['Int'];
};


export type MutationsDeletePixieImageTranslationArgs = {
  id: Scalars['Int'];
};


export type MutationsDeletePixieTranslationArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteScheduleLanguageArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteSchedulePhaseArgs = {
  id: Scalars['Int'];
};


export type MutationsDeleteUserArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationsLoginFacebookUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  facebookLoginId: Scalars['String'];
};


export type MutationsLoginGoogleUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  googleLoginId: Scalars['String'];
};


export type MutationsManageManyToManyCollectionAppSettingArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionFacebookPageArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionFacebookPostArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionFacebookUserProfileArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionGroupArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionPermissionArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionPixieArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionPixieImageArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionPixieImageTranslationArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionPixieTranslationArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionScheduleLanguageArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionSchedulePhaseArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsManageManyToManyCollectionUserArgs = {
  action: Scalars['String'];
  collectionClass: Scalars['String'];
  collectionEntityIds: Array<InputMaybe<Scalars['Int']>>;
  collectionName: Scalars['String'];
  entityClass: Scalars['String'];
  entityId: Scalars['Int'];
};


export type MutationsPasswordChangeArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationsPasswordResetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsPasswordSetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationsPostToPageArgs = {
  input: PostToPageInput;
};


export type MutationsRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationsRegisterArgs = {
  email: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
  username: Scalars['String'];
};


export type MutationsRemoveSecondaryEmailArgs = {
  password: Scalars['String'];
};


export type MutationsResendActivationEmailArgs = {
  email: Scalars['String'];
};


export type MutationsRevokeTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationsSaveAppSettingArgs = {
  input: AppSettingInput;
};


export type MutationsSaveFacebookPageArgs = {
  input: FacebookPageInput;
};


export type MutationsSaveFacebookPostArgs = {
  input: FacebookPostInput;
};


export type MutationsSaveFacebookUserProfileArgs = {
  input: FacebookUserProfileInput;
};


export type MutationsSaveGroupArgs = {
  input: GroupInput;
};


export type MutationsSaveLongLivedAccessTokenAndUpdatePagesForUserArgs = {
  shortLivedAccessToken: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationsSavePermissionArgs = {
  input: PermissionInput;
};


export type MutationsSavePixieArgs = {
  input: PixieInput;
};


export type MutationsSavePixieImageArgs = {
  input: PixieImageInput;
};


export type MutationsSavePixieImageTranslationArgs = {
  input: PixieImageTranslationInput;
};


export type MutationsSavePixieTranslationArgs = {
  input: PixieTranslationInput;
};


export type MutationsSaveScheduleLanguageArgs = {
  input: ScheduleLanguageInput;
};


export type MutationsSaveSchedulePhaseArgs = {
  input: SchedulePhaseInput;
};


export type MutationsSaveUserArgs = {
  input: UserInput;
};


export type MutationsScheduleABunchOfPostsArgs = {
  input: ScheduleABunchOfPostsInput;
};


export type MutationsSchedulePostArgs = {
  input: SchedulePostInput;
};


export type MutationsSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationsSendSecondaryEmailActivationArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationsSetPermissionsForGroupArgs = {
  id: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationsSetPermissionsForUserArgs = {
  id: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationsSwapEmailsArgs = {
  password: Scalars['String'];
};


export type MutationsTokenAuthArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};


export type MutationsUpdateAccountArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};


export type MutationsVerifyAccountArgs = {
  token: Scalars['String'];
};


export type MutationsVerifySecondaryEmailArgs = {
  token: Scalars['String'];
};


export type MutationsVerifyTokenArgs = {
  token: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/**
 * Obtain JSON web token for given user.
 *
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 *
 * Not verified users can login by default. This
 * can be changes on settings.
 *
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  unarchiving?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
};

export type PageFeedPost = {
  __typename?: 'PageFeedPost';
  createdTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  permalinkUrl?: Maybe<Scalars['String']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/**
 * Change account password when user knows the old password.
 *
 * A new token and refresh token are sent. User must be verified.
 */
export type PasswordChange = {
  __typename?: 'PasswordChange';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Set user password - for passwordless registration
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, set
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordSet = {
  __typename?: 'PasswordSet';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Permission schema. */
export type Permission = Node & {
  __typename?: 'Permission';
  codename: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PermissionFastConnection = {
  __typename?: 'PermissionFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PermissionFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PermissionFastEdge = {
  __typename?: 'PermissionFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Permission>;
};

export type PermissionInput = {
  codename: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};

/** Represents a pixie. */
export type Pixie = Node & {
  __typename?: 'Pixie';
  content: Scalars['String'];
  dateAdded: Scalars['DateTime'];
  /** Facebook image details for HTML img tag */
  facebookImage?: Maybe<Image>;
  id: Scalars['ID'];
  /** The images for this pixie. */
  images?: Maybe<Array<Maybe<PixieImage>>>;
  isAutoPostingEnabled: Scalars['Boolean'];
  primaryLanguage: Scalars['String'];
  status: PixieStatus;
  /** Thumbnail image details for HTML img tag */
  thumbnail?: Maybe<Image>;
  /** All translations for this entity. The translation_schema_object_type must be set. */
  translations?: Maybe<Array<Maybe<PixieTranslation>>>;
  typeOfContent: PixieTypeOfContent;
};

export type PixieFastConnection = {
  __typename?: 'PixieFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PixieFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PixieFastEdge = {
  __typename?: 'PixieFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Pixie>;
};

/** Represents an image for a pixie. */
export type PixieImage = Node & {
  __typename?: 'PixieImage';
  height: Scalars['Int'];
  id: Scalars['ID'];
  /** Image details for HTML img tag */
  image?: Maybe<Image>;
  pixie: Pixie;
  primaryLanguage: Scalars['String'];
  /** All translations for this entity. The translation_schema_object_type must be set. */
  translations?: Maybe<Array<Maybe<PixieImageTranslation>>>;
  width: Scalars['Int'];
};

export type PixieImageFastConnection = {
  __typename?: 'PixieImageFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PixieImageFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PixieImageFastEdge = {
  __typename?: 'PixieImageFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PixieImage>;
};

export type PixieImageInput = {
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  pixieId: Scalars['Int'];
  /** The primary language of the entity. */
  primaryLanguage: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
};

/** Represents a translation for a pixie image. */
export type PixieImageTranslation = Node & {
  __typename?: 'PixieImageTranslation';
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  pixieImage: PixieImage;
  title: Scalars['String'];
};

export type PixieImageTranslationFastConnection = {
  __typename?: 'PixieImageTranslationFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PixieImageTranslationFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PixieImageTranslationFastEdge = {
  __typename?: 'PixieImageTranslationFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PixieImageTranslation>;
};

export type PixieImageTranslationInput = {
  id?: InputMaybe<Scalars['Int']>;
  /** The language of the translation. */
  language: Scalars['String'];
  pixieImageId: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type PixieInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isAutoPostingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The primary language of the entity. */
  primaryLanguage: Scalars['String'];
  status: Scalars['String'];
  typeOfContent: Scalars['String'];
};

/** An enumeration. */
export enum PixieStatus {
  /** DRAFT */
  Draft = 'DRAFT',
  /** PUBLISHED */
  Published = 'PUBLISHED'
}

/** Represents a translation for a pixie. */
export type PixieTranslation = Node & {
  __typename?: 'PixieTranslation';
  autoPostCount: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The language for the translated fields. */
  language: Scalars['String'];
  pixie: Pixie;
  /** A unique slug that can be used in URLs */
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type PixieTranslationFastConnection = {
  __typename?: 'PixieTranslationFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<PixieTranslationFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PixieTranslationFastEdge = {
  __typename?: 'PixieTranslationFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PixieTranslation>;
};

export type PixieTranslationInput = {
  autoPostCount?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** The language of the translation. */
  language: Scalars['String'];
  pixieId: Scalars['Int'];
  /** The slug of the entity. */
  slug: Scalars['String'];
  title: Scalars['String'];
};

/** An enumeration. */
export enum PixieTypeOfContent {
  /** IMAGES */
  Images = 'IMAGES',
  /** VIDEO */
  Video = 'VIDEO'
}

/** Post to a page */
export type PostToPage = {
  __typename?: 'PostToPage';
  id?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PostToPageInput = {
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pageId: Scalars['Int'];
  photo?: InputMaybe<Scalars['Upload']>;
};

export type Query = {
  __typename?: 'Query';
  appSettings?: Maybe<AppSettingFastConnection>;
  checkPassword?: Maybe<Scalars['Boolean']>;
  facebookPages?: Maybe<FacebookPageFastConnection>;
  facebookPosts?: Maybe<FacebookPostFastConnection>;
  facebookUserProfiles?: Maybe<FacebookUserProfileFastConnection>;
  groupHasPermissions?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<GroupFastConnection>;
  me?: Maybe<UserNode>;
  nextJsRevalidationUrl?: Maybe<Scalars['String']>;
  pageFeedPost?: Maybe<PageFeedPost>;
  pageFeedPosts?: Maybe<Array<Maybe<PageFeedPost>>>;
  permissions?: Maybe<PermissionFastConnection>;
  permissionsLegacy?: Maybe<Array<Maybe<Permission>>>;
  pixieImageTranslations?: Maybe<PixieImageTranslationFastConnection>;
  pixieImages?: Maybe<PixieImageFastConnection>;
  pixieTranslations?: Maybe<PixieTranslationFastConnection>;
  pixies?: Maybe<PixieFastConnection>;
  randomPixies?: Maybe<Array<Maybe<Pixie>>>;
  readFacebookOgTagsFromUrl?: Maybe<FacebookMetaTagsType>;
  scheduleLanguages?: Maybe<ScheduleLanguageFastConnection>;
  schedulePhases?: Maybe<SchedulePhaseFastConnection>;
  /** Hack: Disables the 'user' field from UserQuery. */
  user?: Maybe<UserNode>;
  userHasPermissions?: Maybe<Scalars['Boolean']>;
  users?: Maybe<UserNodeConnection>;
};


export type QueryAppSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryCheckPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryFacebookPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isActive_In?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  language_Code?: InputMaybe<Scalars['String']>;
  language_Code_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Icontains?: InputMaybe<Scalars['String']>;
  profile_User_Id?: InputMaybe<Scalars['Int']>;
};


export type QueryFacebookPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isPosted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  page_Language_Code?: InputMaybe<Scalars['String']>;
  page_Language_Code_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page_Name_Icontains?: InputMaybe<Scalars['String']>;
  scheduleDate?: InputMaybe<Scalars['String']>;
  scheduleDate_Gte?: InputMaybe<Scalars['String']>;
  scheduleDate_Lte?: InputMaybe<Scalars['String']>;
  typeOfPost_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFacebookUserProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  user_Id?: InputMaybe<Scalars['Int']>;
};


export type QueryGroupHasPermissionsArgs = {
  id: Scalars['Int'];
  permissionCodenames: Array<InputMaybe<Scalars['String']>>;
};


export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  permission_Id?: InputMaybe<Scalars['Int']>;
  user_Id?: InputMaybe<Scalars['Int']>;
};


export type QueryNextJsRevalidationUrlArgs = {
  target?: InputMaybe<Scalars['String']>;
};


export type QueryPageFeedPostArgs = {
  feedPostId: Scalars['String'];
  pageId: Scalars['Int'];
};


export type QueryPageFeedPostsArgs = {
  pageId: Scalars['Int'];
};


export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  codename?: InputMaybe<Scalars['String']>;
  codename_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  group_Id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  user_Id?: InputMaybe<Scalars['Int']>;
};


export type QueryPixieImageTranslationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  pixieImage_Id?: InputMaybe<Scalars['Int']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryPixieImagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  pixie_Id?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
};


export type QueryPixieTranslationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  pixie_Id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  title_Icontains?: InputMaybe<Scalars['String']>;
};


export type QueryPixiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_In?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  translation_Language?: InputMaybe<Scalars['String']>;
  translation_Slug?: InputMaybe<Scalars['String']>;
};


export type QueryRandomPixiesArgs = {
  count: Scalars['Int'];
  excludeId?: InputMaybe<Scalars['Int']>;
  language: Scalars['String'];
};


export type QueryReadFacebookOgTagsFromUrlArgs = {
  url: Scalars['String'];
};


export type QueryScheduleLanguagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  code_In?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_In?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QuerySchedulePhasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  facebookPage_Id?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  id_In?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  last?: InputMaybe<Scalars['Int']>;
  sequenceNo?: InputMaybe<Scalars['Int']>;
  sequenceNo_In?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QueryUserHasPermissionsArgs = {
  id: Scalars['Int'];
  permissionCodenames: Array<InputMaybe<Scalars['String']>>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  group_Id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  permission_Id?: InputMaybe<Scalars['Int']>;
  status_Archived?: InputMaybe<Scalars['Boolean']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']>;
  status_Verified?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  username_Icontains?: InputMaybe<Scalars['String']>;
  username_Istartswith?: InputMaybe<Scalars['String']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RefreshToken = {
  __typename?: 'RefreshToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Register user with fields defined in the settings.
 *
 * If the email field of the user model is part of the
 * registration fields (default), check if there is
 * no user with that email or as a secondary email.
 *
 * If it exists, it does not register the user,
 * even if the email field is not defined as unique
 * (default of the default django user model).
 *
 * When creating the user, it also creates a `UserStatus`
 * related to that user, making it possible to track
 * if the user is archived, verified and has a secondary
 * email.
 *
 * Send account verification email.
 *
 * If allowed to not verified users login, return token.
 */
export type Register = {
  __typename?: 'Register';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Remove user secondary email.
 *
 * Require password confirmation.
 */
export type RemoveSecondaryEmail = {
  __typename?: 'RemoveSecondaryEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Sends activation email.
 *
 * It is called resend because theoretically
 * the first activation email was sent when
 * the user registered.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type ResendActivationEmail = {
  __typename?: 'ResendActivationEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RevokeToken = {
  __typename?: 'RevokeToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  revoked?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveAppSetting = {
  __typename?: 'SaveAppSetting';
  object?: Maybe<AppSetting>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveFacebookPage = {
  __typename?: 'SaveFacebookPage';
  object?: Maybe<FacebookPage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveFacebookPost = {
  __typename?: 'SaveFacebookPost';
  object?: Maybe<FacebookPost>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveFacebookUserProfile = {
  __typename?: 'SaveFacebookUserProfile';
  object?: Maybe<FacebookUserProfile>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveGroup = {
  __typename?: 'SaveGroup';
  object?: Maybe<Group>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Save long lived access token and update the pages as well() */
export type SaveLongLivedAccessTokenAndUpdatePagesForUser = {
  __typename?: 'SaveLongLivedAccessTokenAndUpdatePagesForUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePermission = {
  __typename?: 'SavePermission';
  object?: Maybe<Permission>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePixie = {
  __typename?: 'SavePixie';
  object?: Maybe<Pixie>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePixieImage = {
  __typename?: 'SavePixieImage';
  object?: Maybe<PixieImage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePixieImageTranslation = {
  __typename?: 'SavePixieImageTranslation';
  object?: Maybe<PixieImageTranslation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SavePixieTranslation = {
  __typename?: 'SavePixieTranslation';
  object?: Maybe<PixieTranslation>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveScheduleLanguage = {
  __typename?: 'SaveScheduleLanguage';
  object?: Maybe<ScheduleLanguage>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveSchedulePhase = {
  __typename?: 'SaveSchedulePhase';
  object?: Maybe<SchedulePhase>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SaveUser = {
  __typename?: 'SaveUser';
  object?: Maybe<UserNode>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Schedule a bunch of posts */
export type ScheduleABunchOfPosts = {
  __typename?: 'ScheduleABunchOfPosts';
  posts?: Maybe<Array<Maybe<FacebookPost>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ScheduleABunchOfPostsInput = {
  getMessagesFromFileNames?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  pageIds: Array<InputMaybe<Scalars['Int']>>;
  phaseIds: Array<InputMaybe<Scalars['Int']>>;
  photos?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  startDate: Scalars['Date'];
};

/** Represents an available language in the scheduler. */
export type ScheduleLanguage = Node & {
  __typename?: 'ScheduleLanguage';
  code: Scalars['String'];
  facebookPages: FacebookPageConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  sequenceNo: Scalars['Int'];
};


/** Represents an available language in the scheduler. */
export type ScheduleLanguageFacebookPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ScheduleLanguageFastConnection = {
  __typename?: 'ScheduleLanguageFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<ScheduleLanguageFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ScheduleLanguageFastEdge = {
  __typename?: 'ScheduleLanguageFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ScheduleLanguage>;
};

export type ScheduleLanguageInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  sequenceNo: Scalars['Int'];
};

/** Represents a phase (an hour) of a day. */
export type SchedulePhase = Node & {
  __typename?: 'SchedulePhase';
  facebookPages: FacebookPageConnection;
  id: Scalars['ID'];
  schedulePhases: FacebookPostConnection;
  scheduleTime: Scalars['Time'];
  sequenceNo: Scalars['Int'];
};


/** Represents a phase (an hour) of a day. */
export type SchedulePhaseFacebookPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Represents a phase (an hour) of a day. */
export type SchedulePhaseSchedulePhasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SchedulePhaseConnection = {
  __typename?: 'SchedulePhaseConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SchedulePhaseEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SchedulePhase` and its cursor. */
export type SchedulePhaseEdge = {
  __typename?: 'SchedulePhaseEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SchedulePhase>;
};

export type SchedulePhaseFastConnection = {
  __typename?: 'SchedulePhaseFastConnection';
  /** The zero based index of the current page. */
  currentPage?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<SchedulePhaseFastEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  /** The pages of the connection. Assumes that each page will have the same number of items. The first and the last pages might have less items. */
  pages?: Maybe<Array<Maybe<PageInfo>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SchedulePhaseFastEdge = {
  __typename?: 'SchedulePhaseFastEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SchedulePhase>;
};

export type SchedulePhaseInput = {
  id?: InputMaybe<Scalars['Int']>;
  scheduleTime: Scalars['Time'];
  sequenceNo: Scalars['Int'];
};

/** Schedule a post */
export type SchedulePost = {
  __typename?: 'SchedulePost';
  post?: Maybe<FacebookPost>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SchedulePostInput = {
  date: Scalars['Date'];
  link?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pageId: Scalars['Int'];
  phaseId: Scalars['Int'];
  photo?: InputMaybe<Scalars['Upload']>;
  typeOfPost: Scalars['String'];
};

/**
 * Send password reset email.
 *
 * For non verified users, send an activation
 * email instead.
 *
 * Accepts both primary and secondary email.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type SendPasswordResetEmail = {
  __typename?: 'SendPasswordResetEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Send activation to secondary email.
 *
 * User must be verified and confirm password.
 */
export type SendSecondaryEmailActivation = {
  __typename?: 'SendSecondaryEmailActivation';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SetPermissionsForGroup = {
  __typename?: 'SetPermissionsForGroup';
  success?: Maybe<Scalars['Boolean']>;
};

export type SetPermissionsForUser = {
  __typename?: 'SetPermissionsForUser';
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Swap between primary and secondary emails.
 *
 * Require password confirmation.
 */
export type SwapEmails = {
  __typename?: 'SwapEmails';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Update user model fields, defined on settings.
 *
 * User must be verified.
 */
export type UpdateAccount = {
  __typename?: 'UpdateAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Update existing slugs */
export type UpdateExistingSlugs = {
  __typename?: 'UpdateExistingSlugs';
  success?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  archived?: Maybe<Scalars['Boolean']>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  /** The userID from the Facebook SDK authResponse (The ID of the person using your webpage). */
  facebookLoginId: Scalars['String'];
  facebookUserProfiles: FacebookUserProfileConnection;
  firstName: Scalars['String'];
  /** The sub recevied from the decoded Google ID token (which is a JWT token). */
  googleLoginId: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  /** Sometimes the first name and the last name are saved together to one field. */
  name: Scalars['String'];
  nickname: Scalars['String'];
  pk?: Maybe<Scalars['Int']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
};


export type UserNodeFacebookUserProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

/**
 * Verify user account.
 *
 * Receive the token that was sent by email.
 * If the token is valid, make the user verified
 * by making the `user.status.verified` field true.
 */
export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Verify user secondary email.
 *
 * Receive the token that was sent by email.
 * User is already verified when using this mutation.
 *
 * If the token is valid, add the secondary email
 * to `user.status.secondary_email` field.
 *
 * Note that until the secondary email is verified,
 * it has not been saved anywhere beyond the token,
 * so it can still be used to create a new account.
 * After being verified, it will no longer be available.
 */
export type VerifySecondaryEmail = {
  __typename?: 'VerifySecondaryEmail';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyToken = {
  __typename?: 'VerifyToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FacebookPageDetailsFragmentFragment = { __typename?: 'FacebookPage', id: string, name: string, facebookId: string, timezone: number, trackingCode: string, isActive: boolean, nameDetailed?: string | null, profile: { __typename?: 'FacebookUserProfile', id: string, appId: string, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null }, language: { __typename?: 'ScheduleLanguage', id: string, code: string, name: string, sequenceNo: number }, phases: { __typename?: 'SchedulePhaseConnection', edges: Array<{ __typename?: 'SchedulePhaseEdge', node?: { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any } | null } | null> } };

export type PageFeedPostDetailsFragment = { __typename?: 'PageFeedPost', id?: string | null, mediaType?: string | null, createdTime?: string | null, permalinkUrl?: string | null, message?: string | null };

export type FacebookPostDetailsFragmentFragment = { __typename?: 'FacebookPost', id: string, typeOfPost: FacebookPostTypeOfPost, scheduleDate: any, scheduleTime: any, link: string, message: string, isPosted: boolean, facebookPostId: string, mediaFileUrl?: string | null, text?: string | null, page: { __typename?: 'FacebookPage', id: string, name: string, facebookId: string, timezone: number, trackingCode: string, isActive: boolean, nameDetailed?: string | null, profile: { __typename?: 'FacebookUserProfile', id: string, appId: string, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null }, language: { __typename?: 'ScheduleLanguage', id: string, code: string, name: string, sequenceNo: number }, phases: { __typename?: 'SchedulePhaseConnection', edges: Array<{ __typename?: 'SchedulePhaseEdge', node?: { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any } | null } | null> } }, phase: { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any } };

export type FacebookUserProfileDetailsFragmentFragment = { __typename?: 'FacebookUserProfile', id: string, appId: string, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null };

export type ScheduleLanguageDetailsFragmentFragment = { __typename?: 'ScheduleLanguage', id: string, code: string, name: string, sequenceNo: number };

export type SchedulePhaseDetailsFragmentFragment = { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any };

export type DeletePageFeedPostMutationVariables = Exact<{
  pageId: Scalars['Int'];
  feedPostId: Scalars['String'];
}>;


export type DeletePageFeedPostMutation = { __typename?: 'Mutations', deletePageFeedPost?: { __typename?: 'DeletePageFeedPost', success?: boolean | null } | null };

export type PostToPageMutationVariables = Exact<{
  input: PostToPageInput;
}>;


export type PostToPageMutation = { __typename?: 'Mutations', postToPage?: { __typename?: 'PostToPage', success?: boolean | null, id?: string | null } | null };

export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  shortLivedAccessToken: Scalars['String'];
}>;


export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutation = { __typename?: 'Mutations', saveLongLivedAccessTokenAndUpdatePagesForUser?: { __typename?: 'SaveLongLivedAccessTokenAndUpdatePagesForUser', success?: boolean | null } | null };

export type ScheduleABunchOfPostsMutationVariables = Exact<{
  input: ScheduleABunchOfPostsInput;
}>;


export type ScheduleABunchOfPostsMutation = { __typename?: 'Mutations', scheduleABunchOfPosts?: { __typename?: 'ScheduleABunchOfPosts', success?: boolean | null } | null };

export type SchedulePostMutationVariables = Exact<{
  input: SchedulePostInput;
}>;


export type SchedulePostMutation = { __typename?: 'Mutations', schedulePost?: { __typename?: 'SchedulePost', success?: boolean | null, post?: { __typename?: 'FacebookPost', id: string, typeOfPost: FacebookPostTypeOfPost, scheduleDate: any, scheduleTime: any, link: string, message: string, isPosted: boolean, facebookPostId: string, mediaFileUrl?: string | null, text?: string | null, page: { __typename?: 'FacebookPage', id: string, name: string, facebookId: string, timezone: number, trackingCode: string, isActive: boolean, nameDetailed?: string | null, profile: { __typename?: 'FacebookUserProfile', id: string, appId: string, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null }, language: { __typename?: 'ScheduleLanguage', id: string, code: string, name: string, sequenceNo: number }, phases: { __typename?: 'SchedulePhaseConnection', edges: Array<{ __typename?: 'SchedulePhaseEdge', node?: { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any } | null } | null> } }, phase: { __typename?: 'SchedulePhase', id: string, sequenceNo: number, scheduleTime: any } } | null } | null };

export type PageFeedPostQueryVariables = Exact<{
  pageId: Scalars['Int'];
  feedPostId: Scalars['String'];
}>;


export type PageFeedPostQuery = { __typename?: 'Query', pageFeedPost?: { __typename?: 'PageFeedPost', id?: string | null, mediaType?: string | null, createdTime?: string | null, permalinkUrl?: string | null, message?: string | null } | null };

export type PageFeedPostsQueryVariables = Exact<{
  pageId: Scalars['Int'];
}>;


export type PageFeedPostsQuery = { __typename?: 'Query', pageFeedPosts?: Array<{ __typename?: 'PageFeedPost', id?: string | null, mediaType?: string | null, createdTime?: string | null, permalinkUrl?: string | null, message?: string | null } | null> | null };

export type ReadFacebookOgTagsFromUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type ReadFacebookOgTagsFromUrlQuery = { __typename?: 'Query', readFacebookOgTagsFromUrl?: { __typename?: 'FacebookMetaTagsType', ogUrl?: string | null, ogTitle?: string | null, ogImage?: string | null, ogDescription?: string | null } | null };

export type PageInfoDetailsFragmentFragment = { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type UserDetailsFragmentFragment = { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string };

export type LoginFacebookUserMutationVariables = Exact<{
  facebookLoginId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type LoginFacebookUserMutation = { __typename?: 'Mutations', loginFacebookUser?: { __typename?: 'LoginFacebookUser', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type LoginGoogleUserMutationVariables = Exact<{
  googleLoginId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type LoginGoogleUserMutation = { __typename?: 'Mutations', loginGoogleUser?: { __typename?: 'LoginGoogleUser', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = { __typename?: 'Mutations', tokenAuth?: { __typename?: 'ObtainJSONWebToken', success?: boolean | null, token?: string | null, refreshToken?: string | null, user?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null } | null };

export type PasswordResetMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
}>;


export type PasswordResetMutation = { __typename?: 'Mutations', passwordReset?: { __typename?: 'PasswordReset', success?: boolean | null } | null };

export type RefreshUserTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshUserTokenMutation = { __typename?: 'Mutations', refreshToken?: { __typename?: 'RefreshToken', success?: boolean | null, token?: string | null, refreshToken?: string | null } | null };

export type RegisterUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutations', register?: { __typename?: 'Register', success?: boolean | null, token?: string | null, refreshToken?: string | null } | null };

export type ResendActivationEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationEmailMutation = { __typename?: 'Mutations', resendActivationEmail?: { __typename?: 'ResendActivationEmail', success?: boolean | null } | null };

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendPasswordResetEmailMutation = { __typename?: 'Mutations', sendPasswordResetEmail?: { __typename?: 'SendPasswordResetEmail', success?: boolean | null } | null };

export type SetPermissionsForGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type SetPermissionsForGroupMutation = { __typename?: 'Mutations', setPermissionsForGroup?: { __typename?: 'SetPermissionsForGroup', success?: boolean | null } | null };

export type SetPermissionsForUserMutationVariables = Exact<{
  id: Scalars['Int'];
  permissionCodenames?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type SetPermissionsForUserMutation = { __typename?: 'Mutations', setPermissionsForUser?: { __typename?: 'SetPermissionsForUser', success?: boolean | null } | null };

export type VerifyAccountMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyAccountMutation = { __typename?: 'Mutations', verifyAccount?: { __typename?: 'VerifyAccount', success?: boolean | null } | null };

export type VerifyUserTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyUserTokenMutation = { __typename?: 'Mutations', verifyToken?: { __typename?: 'VerifyToken', success?: boolean | null } | null };

export type CheckPasswordQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type CheckPasswordQuery = { __typename?: 'Query', checkPassword?: boolean | null };

export type GroupHasPermissionsQueryVariables = Exact<{
  id: Scalars['Int'];
  permissionCodenames: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type GroupHasPermissionsQuery = { __typename?: 'Query', groupHasPermissions?: boolean | null };

export type ListPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPermissionsQuery = { __typename?: 'Query', permissionsLegacy?: Array<{ __typename?: 'Permission', id: string, codename: string, name: string } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserNode', id: string, pk?: number | null, username: string, email: string, verified?: boolean | null, isStaff: boolean, isActive: boolean, dateJoined: any, firstName: string, lastName: string, nickname: string, name: string, facebookLoginId: string, googleLoginId: string } | null };

export type UserHasPermissionsQueryVariables = Exact<{
  id: Scalars['Int'];
  permissionCodenames: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type UserHasPermissionsQuery = { __typename?: 'Query', userHasPermissions?: boolean | null };

export const PageFeedPostDetailsFragmentDoc = gql`
    fragment PageFeedPostDetails on PageFeedPost {
  id
  mediaType
  createdTime
  permalinkUrl
  message
}
    `;
export const UserDetailsFragmentFragmentDoc = gql`
    fragment UserDetailsFragment on UserNode {
  id
  pk
  username
  email
  verified
  isStaff
  isActive
  dateJoined
  firstName
  lastName
  nickname
  name
  facebookLoginId
  googleLoginId
}
    `;
export const FacebookUserProfileDetailsFragmentFragmentDoc = gql`
    fragment FacebookUserProfileDetailsFragment on FacebookUserProfile {
  id
  appId
  user {
    ...UserDetailsFragment
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export const ScheduleLanguageDetailsFragmentFragmentDoc = gql`
    fragment ScheduleLanguageDetailsFragment on ScheduleLanguage {
  id
  code
  name
  sequenceNo
}
    `;
export const SchedulePhaseDetailsFragmentFragmentDoc = gql`
    fragment SchedulePhaseDetailsFragment on SchedulePhase {
  id
  sequenceNo
  scheduleTime
}
    `;
export const FacebookPageDetailsFragmentFragmentDoc = gql`
    fragment FacebookPageDetailsFragment on FacebookPage {
  id
  name
  profile {
    ...FacebookUserProfileDetailsFragment
  }
  language {
    ...ScheduleLanguageDetailsFragment
  }
  facebookId
  timezone
  trackingCode
  phases {
    edges {
      node {
        ...SchedulePhaseDetailsFragment
      }
    }
  }
  isActive
  nameDetailed
}
    ${FacebookUserProfileDetailsFragmentFragmentDoc}
${ScheduleLanguageDetailsFragmentFragmentDoc}
${SchedulePhaseDetailsFragmentFragmentDoc}`;
export const FacebookPostDetailsFragmentFragmentDoc = gql`
    fragment FacebookPostDetailsFragment on FacebookPost {
  id
  typeOfPost
  page {
    ...FacebookPageDetailsFragment
  }
  phase {
    ...SchedulePhaseDetailsFragment
  }
  scheduleDate
  scheduleTime
  link
  message
  isPosted
  facebookPostId
  mediaFileUrl
  text
}
    ${FacebookPageDetailsFragmentFragmentDoc}
${SchedulePhaseDetailsFragmentFragmentDoc}`;
export const PageInfoDetailsFragmentFragmentDoc = gql`
    fragment PageInfoDetailsFragment on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const DeletePageFeedPostDocument = gql`
    mutation DeletePageFeedPost($pageId: Int!, $feedPostId: String!) {
  deletePageFeedPost(pageId: $pageId, feedPostId: $feedPostId) {
    success
  }
}
    `;
export type DeletePageFeedPostMutationFn = Apollo.MutationFunction<DeletePageFeedPostMutation, DeletePageFeedPostMutationVariables>;

/**
 * __useDeletePageFeedPostMutation__
 *
 * To run a mutation, you first call `useDeletePageFeedPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageFeedPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageFeedPostMutation, { data, loading, error }] = useDeletePageFeedPostMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      feedPostId: // value for 'feedPostId'
 *   },
 * });
 */
export function useDeletePageFeedPostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageFeedPostMutation, DeletePageFeedPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageFeedPostMutation, DeletePageFeedPostMutationVariables>(DeletePageFeedPostDocument, options);
      }
export type DeletePageFeedPostMutationHookResult = ReturnType<typeof useDeletePageFeedPostMutation>;
export type DeletePageFeedPostMutationResult = Apollo.MutationResult<DeletePageFeedPostMutation>;
export type DeletePageFeedPostMutationOptions = Apollo.BaseMutationOptions<DeletePageFeedPostMutation, DeletePageFeedPostMutationVariables>;
export const PostToPageDocument = gql`
    mutation PostToPage($input: PostToPageInput!) {
  postToPage(input: $input) {
    success
    id
  }
}
    `;
export type PostToPageMutationFn = Apollo.MutationFunction<PostToPageMutation, PostToPageMutationVariables>;

/**
 * __usePostToPageMutation__
 *
 * To run a mutation, you first call `usePostToPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostToPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postToPageMutation, { data, loading, error }] = usePostToPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostToPageMutation(baseOptions?: Apollo.MutationHookOptions<PostToPageMutation, PostToPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostToPageMutation, PostToPageMutationVariables>(PostToPageDocument, options);
      }
export type PostToPageMutationHookResult = ReturnType<typeof usePostToPageMutation>;
export type PostToPageMutationResult = Apollo.MutationResult<PostToPageMutation>;
export type PostToPageMutationOptions = Apollo.BaseMutationOptions<PostToPageMutation, PostToPageMutationVariables>;
export const SaveLongLivedAccessTokenAndUpdatePagesForUserDocument = gql`
    mutation SaveLongLivedAccessTokenAndUpdatePagesForUser($userId: Int!, $shortLivedAccessToken: String!) {
  saveLongLivedAccessTokenAndUpdatePagesForUser(
    userId: $userId
    shortLivedAccessToken: $shortLivedAccessToken
  ) {
    success
  }
}
    `;
export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutationFn = Apollo.MutationFunction<SaveLongLivedAccessTokenAndUpdatePagesForUserMutation, SaveLongLivedAccessTokenAndUpdatePagesForUserMutationVariables>;

/**
 * __useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation__
 *
 * To run a mutation, you first call `useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLongLivedAccessTokenAndUpdatePagesForUserMutation, { data, loading, error }] = useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      shortLivedAccessToken: // value for 'shortLivedAccessToken'
 *   },
 * });
 */
export function useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation(baseOptions?: Apollo.MutationHookOptions<SaveLongLivedAccessTokenAndUpdatePagesForUserMutation, SaveLongLivedAccessTokenAndUpdatePagesForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLongLivedAccessTokenAndUpdatePagesForUserMutation, SaveLongLivedAccessTokenAndUpdatePagesForUserMutationVariables>(SaveLongLivedAccessTokenAndUpdatePagesForUserDocument, options);
      }
export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutationHookResult = ReturnType<typeof useSaveLongLivedAccessTokenAndUpdatePagesForUserMutation>;
export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutationResult = Apollo.MutationResult<SaveLongLivedAccessTokenAndUpdatePagesForUserMutation>;
export type SaveLongLivedAccessTokenAndUpdatePagesForUserMutationOptions = Apollo.BaseMutationOptions<SaveLongLivedAccessTokenAndUpdatePagesForUserMutation, SaveLongLivedAccessTokenAndUpdatePagesForUserMutationVariables>;
export const ScheduleABunchOfPostsDocument = gql`
    mutation ScheduleABunchOfPosts($input: ScheduleABunchOfPostsInput!) {
  scheduleABunchOfPosts(input: $input) {
    success
  }
}
    `;
export type ScheduleABunchOfPostsMutationFn = Apollo.MutationFunction<ScheduleABunchOfPostsMutation, ScheduleABunchOfPostsMutationVariables>;

/**
 * __useScheduleABunchOfPostsMutation__
 *
 * To run a mutation, you first call `useScheduleABunchOfPostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleABunchOfPostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleABunchOfPostsMutation, { data, loading, error }] = useScheduleABunchOfPostsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleABunchOfPostsMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleABunchOfPostsMutation, ScheduleABunchOfPostsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleABunchOfPostsMutation, ScheduleABunchOfPostsMutationVariables>(ScheduleABunchOfPostsDocument, options);
      }
export type ScheduleABunchOfPostsMutationHookResult = ReturnType<typeof useScheduleABunchOfPostsMutation>;
export type ScheduleABunchOfPostsMutationResult = Apollo.MutationResult<ScheduleABunchOfPostsMutation>;
export type ScheduleABunchOfPostsMutationOptions = Apollo.BaseMutationOptions<ScheduleABunchOfPostsMutation, ScheduleABunchOfPostsMutationVariables>;
export const SchedulePostDocument = gql`
    mutation SchedulePost($input: SchedulePostInput!) {
  schedulePost(input: $input) {
    success
    post {
      ...FacebookPostDetailsFragment
    }
  }
}
    ${FacebookPostDetailsFragmentFragmentDoc}`;
export type SchedulePostMutationFn = Apollo.MutationFunction<SchedulePostMutation, SchedulePostMutationVariables>;

/**
 * __useSchedulePostMutation__
 *
 * To run a mutation, you first call `useSchedulePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchedulePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schedulePostMutation, { data, loading, error }] = useSchedulePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSchedulePostMutation(baseOptions?: Apollo.MutationHookOptions<SchedulePostMutation, SchedulePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SchedulePostMutation, SchedulePostMutationVariables>(SchedulePostDocument, options);
      }
export type SchedulePostMutationHookResult = ReturnType<typeof useSchedulePostMutation>;
export type SchedulePostMutationResult = Apollo.MutationResult<SchedulePostMutation>;
export type SchedulePostMutationOptions = Apollo.BaseMutationOptions<SchedulePostMutation, SchedulePostMutationVariables>;
export const PageFeedPostDocument = gql`
    query PageFeedPost($pageId: Int!, $feedPostId: String!) {
  pageFeedPost(pageId: $pageId, feedPostId: $feedPostId) {
    ...PageFeedPostDetails
  }
}
    ${PageFeedPostDetailsFragmentDoc}`;

/**
 * __usePageFeedPostQuery__
 *
 * To run a query within a React component, call `usePageFeedPostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageFeedPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageFeedPostQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      feedPostId: // value for 'feedPostId'
 *   },
 * });
 */
export function usePageFeedPostQuery(baseOptions: Apollo.QueryHookOptions<PageFeedPostQuery, PageFeedPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageFeedPostQuery, PageFeedPostQueryVariables>(PageFeedPostDocument, options);
      }
export function usePageFeedPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageFeedPostQuery, PageFeedPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageFeedPostQuery, PageFeedPostQueryVariables>(PageFeedPostDocument, options);
        }
export type PageFeedPostQueryHookResult = ReturnType<typeof usePageFeedPostQuery>;
export type PageFeedPostLazyQueryHookResult = ReturnType<typeof usePageFeedPostLazyQuery>;
export type PageFeedPostQueryResult = Apollo.QueryResult<PageFeedPostQuery, PageFeedPostQueryVariables>;
export const PageFeedPostsDocument = gql`
    query PageFeedPosts($pageId: Int!) {
  pageFeedPosts(pageId: $pageId) {
    ...PageFeedPostDetails
  }
}
    ${PageFeedPostDetailsFragmentDoc}`;

/**
 * __usePageFeedPostsQuery__
 *
 * To run a query within a React component, call `usePageFeedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageFeedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageFeedPostsQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *   },
 * });
 */
export function usePageFeedPostsQuery(baseOptions: Apollo.QueryHookOptions<PageFeedPostsQuery, PageFeedPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageFeedPostsQuery, PageFeedPostsQueryVariables>(PageFeedPostsDocument, options);
      }
export function usePageFeedPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageFeedPostsQuery, PageFeedPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageFeedPostsQuery, PageFeedPostsQueryVariables>(PageFeedPostsDocument, options);
        }
export type PageFeedPostsQueryHookResult = ReturnType<typeof usePageFeedPostsQuery>;
export type PageFeedPostsLazyQueryHookResult = ReturnType<typeof usePageFeedPostsLazyQuery>;
export type PageFeedPostsQueryResult = Apollo.QueryResult<PageFeedPostsQuery, PageFeedPostsQueryVariables>;
export const ReadFacebookOgTagsFromUrlDocument = gql`
    query ReadFacebookOgTagsFromUrl($url: String!) {
  readFacebookOgTagsFromUrl(url: $url) {
    ogUrl
    ogTitle
    ogImage
    ogDescription
  }
}
    `;

/**
 * __useReadFacebookOgTagsFromUrlQuery__
 *
 * To run a query within a React component, call `useReadFacebookOgTagsFromUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadFacebookOgTagsFromUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadFacebookOgTagsFromUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useReadFacebookOgTagsFromUrlQuery(baseOptions: Apollo.QueryHookOptions<ReadFacebookOgTagsFromUrlQuery, ReadFacebookOgTagsFromUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadFacebookOgTagsFromUrlQuery, ReadFacebookOgTagsFromUrlQueryVariables>(ReadFacebookOgTagsFromUrlDocument, options);
      }
export function useReadFacebookOgTagsFromUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadFacebookOgTagsFromUrlQuery, ReadFacebookOgTagsFromUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadFacebookOgTagsFromUrlQuery, ReadFacebookOgTagsFromUrlQueryVariables>(ReadFacebookOgTagsFromUrlDocument, options);
        }
export type ReadFacebookOgTagsFromUrlQueryHookResult = ReturnType<typeof useReadFacebookOgTagsFromUrlQuery>;
export type ReadFacebookOgTagsFromUrlLazyQueryHookResult = ReturnType<typeof useReadFacebookOgTagsFromUrlLazyQuery>;
export type ReadFacebookOgTagsFromUrlQueryResult = Apollo.QueryResult<ReadFacebookOgTagsFromUrlQuery, ReadFacebookOgTagsFromUrlQueryVariables>;
export const LoginFacebookUserDocument = gql`
    mutation LoginFacebookUser($facebookLoginId: String!, $email: String) {
  loginFacebookUser(facebookLoginId: $facebookLoginId, email: $email) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginFacebookUserMutationFn = Apollo.MutationFunction<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>;

/**
 * __useLoginFacebookUserMutation__
 *
 * To run a mutation, you first call `useLoginFacebookUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFacebookUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFacebookUserMutation, { data, loading, error }] = useLoginFacebookUserMutation({
 *   variables: {
 *      facebookLoginId: // value for 'facebookLoginId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginFacebookUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>(LoginFacebookUserDocument, options);
      }
export type LoginFacebookUserMutationHookResult = ReturnType<typeof useLoginFacebookUserMutation>;
export type LoginFacebookUserMutationResult = Apollo.MutationResult<LoginFacebookUserMutation>;
export type LoginFacebookUserMutationOptions = Apollo.BaseMutationOptions<LoginFacebookUserMutation, LoginFacebookUserMutationVariables>;
export const LoginGoogleUserDocument = gql`
    mutation LoginGoogleUser($googleLoginId: String!, $email: String) {
  loginGoogleUser(googleLoginId: $googleLoginId, email: $email) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginGoogleUserMutationFn = Apollo.MutationFunction<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>;

/**
 * __useLoginGoogleUserMutation__
 *
 * To run a mutation, you first call `useLoginGoogleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleUserMutation, { data, loading, error }] = useLoginGoogleUserMutation({
 *   variables: {
 *      googleLoginId: // value for 'googleLoginId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLoginGoogleUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>(LoginGoogleUserDocument, options);
      }
export type LoginGoogleUserMutationHookResult = ReturnType<typeof useLoginGoogleUserMutation>;
export type LoginGoogleUserMutationResult = Apollo.MutationResult<LoginGoogleUserMutation>;
export type LoginGoogleUserMutationOptions = Apollo.BaseMutationOptions<LoginGoogleUserMutation, LoginGoogleUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    success
    token
    refreshToken
    user {
      ...UserDetailsFragment
    }
  }
}
    ${UserDetailsFragmentFragmentDoc}`;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
  passwordReset(
    token: $token
    newPassword1: $newPassword1
    newPassword2: $newPassword2
  ) {
    success
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword1: // value for 'newPassword1'
 *      newPassword2: // value for 'newPassword2'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const RefreshUserTokenDocument = gql`
    mutation RefreshUserToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    success
    token
    refreshToken
  }
}
    `;
export type RefreshUserTokenMutationFn = Apollo.MutationFunction<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>;

/**
 * __useRefreshUserTokenMutation__
 *
 * To run a mutation, you first call `useRefreshUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserTokenMutation, { data, loading, error }] = useRefreshUserTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>(RefreshUserTokenDocument, options);
      }
export type RefreshUserTokenMutationHookResult = ReturnType<typeof useRefreshUserTokenMutation>;
export type RefreshUserTokenMutationResult = Apollo.MutationResult<RefreshUserTokenMutation>;
export type RefreshUserTokenMutationOptions = Apollo.BaseMutationOptions<RefreshUserTokenMutation, RefreshUserTokenMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($email: String!, $password: String!) {
  register(
    email: $email
    username: $email
    password1: $password
    password2: $password
  ) {
    success
    token
    refreshToken
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const ResendActivationEmailDocument = gql`
    mutation ResendActivationEmail($email: String!) {
  resendActivationEmail(email: $email) {
    success
  }
}
    `;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>(ResendActivationEmailDocument, options);
      }
export type ResendActivationEmailMutationHookResult = ReturnType<typeof useResendActivationEmailMutation>;
export type ResendActivationEmailMutationResult = Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email) {
    success
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, options);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const SetPermissionsForGroupDocument = gql`
    mutation SetPermissionsForGroup($id: Int!, $permissionCodenames: [String]) {
  setPermissionsForGroup(id: $id, permissionCodenames: $permissionCodenames) {
    success
  }
}
    `;
export type SetPermissionsForGroupMutationFn = Apollo.MutationFunction<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>;

/**
 * __useSetPermissionsForGroupMutation__
 *
 * To run a mutation, you first call `useSetPermissionsForGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPermissionsForGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPermissionsForGroupMutation, { data, loading, error }] = useSetPermissionsForGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permissionCodenames: // value for 'permissionCodenames'
 *   },
 * });
 */
export function useSetPermissionsForGroupMutation(baseOptions?: Apollo.MutationHookOptions<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>(SetPermissionsForGroupDocument, options);
      }
export type SetPermissionsForGroupMutationHookResult = ReturnType<typeof useSetPermissionsForGroupMutation>;
export type SetPermissionsForGroupMutationResult = Apollo.MutationResult<SetPermissionsForGroupMutation>;
export type SetPermissionsForGroupMutationOptions = Apollo.BaseMutationOptions<SetPermissionsForGroupMutation, SetPermissionsForGroupMutationVariables>;
export const SetPermissionsForUserDocument = gql`
    mutation SetPermissionsForUser($id: Int!, $permissionCodenames: [String]) {
  setPermissionsForUser(id: $id, permissionCodenames: $permissionCodenames) {
    success
  }
}
    `;
export type SetPermissionsForUserMutationFn = Apollo.MutationFunction<SetPermissionsForUserMutation, SetPermissionsForUserMutationVariables>;

/**
 * __useSetPermissionsForUserMutation__
 *
 * To run a mutation, you first call `useSetPermissionsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPermissionsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPermissionsForUserMutation, { data, loading, error }] = useSetPermissionsForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      permissionCodenames: // value for 'permissionCodenames'
 *   },
 * });
 */
export function useSetPermissionsForUserMutation(baseOptions?: Apollo.MutationHookOptions<SetPermissionsForUserMutation, SetPermissionsForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPermissionsForUserMutation, SetPermissionsForUserMutationVariables>(SetPermissionsForUserDocument, options);
      }
export type SetPermissionsForUserMutationHookResult = ReturnType<typeof useSetPermissionsForUserMutation>;
export type SetPermissionsForUserMutationResult = Apollo.MutationResult<SetPermissionsForUserMutation>;
export type SetPermissionsForUserMutationOptions = Apollo.BaseMutationOptions<SetPermissionsForUserMutation, SetPermissionsForUserMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation VerifyAccount($token: String!) {
  verifyAccount(token: $token) {
    success
  }
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, options);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const VerifyUserTokenDocument = gql`
    mutation VerifyUserToken($token: String!) {
  verifyToken(token: $token) {
    success
  }
}
    `;
export type VerifyUserTokenMutationFn = Apollo.MutationFunction<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>;

/**
 * __useVerifyUserTokenMutation__
 *
 * To run a mutation, you first call `useVerifyUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserTokenMutation, { data, loading, error }] = useVerifyUserTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyUserTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>(VerifyUserTokenDocument, options);
      }
export type VerifyUserTokenMutationHookResult = ReturnType<typeof useVerifyUserTokenMutation>;
export type VerifyUserTokenMutationResult = Apollo.MutationResult<VerifyUserTokenMutation>;
export type VerifyUserTokenMutationOptions = Apollo.BaseMutationOptions<VerifyUserTokenMutation, VerifyUserTokenMutationVariables>;
export const CheckPasswordDocument = gql`
    query CheckPassword($email: String!, $password: String!) {
  checkPassword(email: $email, password: $password)
}
    `;

/**
 * __useCheckPasswordQuery__
 *
 * To run a query within a React component, call `useCheckPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
      }
export function useCheckPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
        }
export type CheckPasswordQueryHookResult = ReturnType<typeof useCheckPasswordQuery>;
export type CheckPasswordLazyQueryHookResult = ReturnType<typeof useCheckPasswordLazyQuery>;
export type CheckPasswordQueryResult = Apollo.QueryResult<CheckPasswordQuery, CheckPasswordQueryVariables>;
export const GroupHasPermissionsDocument = gql`
    query GroupHasPermissions($id: Int!, $permissionCodenames: [String]!) {
  groupHasPermissions(id: $id, permissionCodenames: $permissionCodenames)
}
    `;

/**
 * __useGroupHasPermissionsQuery__
 *
 * To run a query within a React component, call `useGroupHasPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupHasPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupHasPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      permissionCodenames: // value for 'permissionCodenames'
 *   },
 * });
 */
export function useGroupHasPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GroupHasPermissionsQuery, GroupHasPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupHasPermissionsQuery, GroupHasPermissionsQueryVariables>(GroupHasPermissionsDocument, options);
      }
export function useGroupHasPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupHasPermissionsQuery, GroupHasPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupHasPermissionsQuery, GroupHasPermissionsQueryVariables>(GroupHasPermissionsDocument, options);
        }
export type GroupHasPermissionsQueryHookResult = ReturnType<typeof useGroupHasPermissionsQuery>;
export type GroupHasPermissionsLazyQueryHookResult = ReturnType<typeof useGroupHasPermissionsLazyQuery>;
export type GroupHasPermissionsQueryResult = Apollo.QueryResult<GroupHasPermissionsQuery, GroupHasPermissionsQueryVariables>;
export const ListPermissionsDocument = gql`
    query ListPermissions {
  permissionsLegacy {
    id
    codename
    name
  }
}
    `;

/**
 * __useListPermissionsQuery__
 *
 * To run a query within a React component, call `useListPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<ListPermissionsQuery, ListPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPermissionsQuery, ListPermissionsQueryVariables>(ListPermissionsDocument, options);
      }
export function useListPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPermissionsQuery, ListPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPermissionsQuery, ListPermissionsQueryVariables>(ListPermissionsDocument, options);
        }
export type ListPermissionsQueryHookResult = ReturnType<typeof useListPermissionsQuery>;
export type ListPermissionsLazyQueryHookResult = ReturnType<typeof useListPermissionsLazyQuery>;
export type ListPermissionsQueryResult = Apollo.QueryResult<ListPermissionsQuery, ListPermissionsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserDetailsFragment
  }
}
    ${UserDetailsFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserHasPermissionsDocument = gql`
    query UserHasPermissions($id: Int!, $permissionCodenames: [String]!) {
  userHasPermissions(id: $id, permissionCodenames: $permissionCodenames)
}
    `;

/**
 * __useUserHasPermissionsQuery__
 *
 * To run a query within a React component, call `useUserHasPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasPermissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      permissionCodenames: // value for 'permissionCodenames'
 *   },
 * });
 */
export function useUserHasPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserHasPermissionsQuery, UserHasPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHasPermissionsQuery, UserHasPermissionsQueryVariables>(UserHasPermissionsDocument, options);
      }
export function useUserHasPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHasPermissionsQuery, UserHasPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHasPermissionsQuery, UserHasPermissionsQueryVariables>(UserHasPermissionsDocument, options);
        }
export type UserHasPermissionsQueryHookResult = ReturnType<typeof useUserHasPermissionsQuery>;
export type UserHasPermissionsLazyQueryHookResult = ReturnType<typeof useUserHasPermissionsLazyQuery>;
export type UserHasPermissionsQueryResult = Apollo.QueryResult<UserHasPermissionsQuery, UserHasPermissionsQueryVariables>;
export type AppSettingKeySpecifier = ('id' | 'isPublic' | 'name' | 'value' | AppSettingKeySpecifier)[];
export type AppSettingFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isPublic?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppSettingFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | AppSettingFastConnectionKeySpecifier)[];
export type AppSettingFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AppSettingFastEdgeKeySpecifier = ('cursor' | 'node' | AppSettingFastEdgeKeySpecifier)[];
export type AppSettingFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArchiveAccountKeySpecifier = ('errors' | 'success' | ArchiveAccountKeySpecifier)[];
export type ArchiveAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ChangePasswordKeySpecifier = ('success' | ChangePasswordKeySpecifier)[];
export type ChangePasswordFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreatePixieWithImagesKeySpecifier = ('pixie' | 'success' | CreatePixieWithImagesKeySpecifier)[];
export type CreatePixieWithImagesFieldPolicy = {
	pixie?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteAccountKeySpecifier = ('errors' | 'success' | DeleteAccountKeySpecifier)[];
export type DeleteAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteAppSettingKeySpecifier = ('object' | 'success' | DeleteAppSettingKeySpecifier)[];
export type DeleteAppSettingFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteFacebookPageKeySpecifier = ('object' | 'success' | DeleteFacebookPageKeySpecifier)[];
export type DeleteFacebookPageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteFacebookPostKeySpecifier = ('object' | 'success' | DeleteFacebookPostKeySpecifier)[];
export type DeleteFacebookPostFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteFacebookUserProfileKeySpecifier = ('object' | 'success' | DeleteFacebookUserProfileKeySpecifier)[];
export type DeleteFacebookUserProfileFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteGroupKeySpecifier = ('object' | 'success' | DeleteGroupKeySpecifier)[];
export type DeleteGroupFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePageFeedPostKeySpecifier = ('success' | DeletePageFeedPostKeySpecifier)[];
export type DeletePageFeedPostFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePermissionKeySpecifier = ('object' | 'success' | DeletePermissionKeySpecifier)[];
export type DeletePermissionFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePixieKeySpecifier = ('object' | 'success' | DeletePixieKeySpecifier)[];
export type DeletePixieFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePixieImageKeySpecifier = ('object' | 'success' | DeletePixieImageKeySpecifier)[];
export type DeletePixieImageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePixieImageTranslationKeySpecifier = ('object' | 'success' | DeletePixieImageTranslationKeySpecifier)[];
export type DeletePixieImageTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeletePixieTranslationKeySpecifier = ('object' | 'success' | DeletePixieTranslationKeySpecifier)[];
export type DeletePixieTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteScheduleLanguageKeySpecifier = ('object' | 'success' | DeleteScheduleLanguageKeySpecifier)[];
export type DeleteScheduleLanguageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteSchedulePhaseKeySpecifier = ('object' | 'success' | DeleteSchedulePhaseKeySpecifier)[];
export type DeleteSchedulePhaseFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeleteUserKeySpecifier = ('success' | DeleteUserKeySpecifier)[];
export type DeleteUserFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookMetaTagsTypeKeySpecifier = ('ogDescription' | 'ogImage' | 'ogTitle' | 'ogUrl' | FacebookMetaTagsTypeKeySpecifier)[];
export type FacebookMetaTagsTypeFieldPolicy = {
	ogDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	ogImage?: FieldPolicy<any> | FieldReadFunction<any>,
	ogTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	ogUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPageKeySpecifier = ('accessToken' | 'facebookId' | 'facebookPosts' | 'id' | 'isActive' | 'language' | 'name' | 'nameDetailed' | 'phases' | 'phasesList' | 'profile' | 'timezone' | 'trackingCode' | FacebookPageKeySpecifier)[];
export type FacebookPageFieldPolicy = {
	accessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookId?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookPosts?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isActive?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	nameDetailed?: FieldPolicy<any> | FieldReadFunction<any>,
	phases?: FieldPolicy<any> | FieldReadFunction<any>,
	phasesList?: FieldPolicy<any> | FieldReadFunction<any>,
	profile?: FieldPolicy<any> | FieldReadFunction<any>,
	timezone?: FieldPolicy<any> | FieldReadFunction<any>,
	trackingCode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPageConnectionKeySpecifier = ('edges' | 'pageInfo' | FacebookPageConnectionKeySpecifier)[];
export type FacebookPageConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPageEdgeKeySpecifier = ('cursor' | 'node' | FacebookPageEdgeKeySpecifier)[];
export type FacebookPageEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPageFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | FacebookPageFastConnectionKeySpecifier)[];
export type FacebookPageFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPageFastEdgeKeySpecifier = ('cursor' | 'node' | FacebookPageFastEdgeKeySpecifier)[];
export type FacebookPageFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPostKeySpecifier = ('facebookPostId' | 'id' | 'isPosted' | 'link' | 'mediaFile' | 'mediaFileUrl' | 'message' | 'ogTags' | 'page' | 'phase' | 'reactionCount' | 'scheduleDate' | 'scheduleTime' | 'shareCount' | 'text' | 'typeOfPost' | FacebookPostKeySpecifier)[];
export type FacebookPostFieldPolicy = {
	facebookPostId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isPosted?: FieldPolicy<any> | FieldReadFunction<any>,
	link?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaFile?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaFileUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	ogTags?: FieldPolicy<any> | FieldReadFunction<any>,
	page?: FieldPolicy<any> | FieldReadFunction<any>,
	phase?: FieldPolicy<any> | FieldReadFunction<any>,
	reactionCount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleDate?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleTime?: FieldPolicy<any> | FieldReadFunction<any>,
	shareCount?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>,
	typeOfPost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPostConnectionKeySpecifier = ('edges' | 'pageInfo' | FacebookPostConnectionKeySpecifier)[];
export type FacebookPostConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPostEdgeKeySpecifier = ('cursor' | 'node' | FacebookPostEdgeKeySpecifier)[];
export type FacebookPostEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPostFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | FacebookPostFastConnectionKeySpecifier)[];
export type FacebookPostFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookPostFastEdgeKeySpecifier = ('cursor' | 'node' | FacebookPostFastEdgeKeySpecifier)[];
export type FacebookPostFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookUserProfileKeySpecifier = ('accessToken' | 'appId' | 'appSecret' | 'facebookPages' | 'id' | 'user' | FacebookUserProfileKeySpecifier)[];
export type FacebookUserProfileFieldPolicy = {
	accessToken?: FieldPolicy<any> | FieldReadFunction<any>,
	appId?: FieldPolicy<any> | FieldReadFunction<any>,
	appSecret?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookPages?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookUserProfileConnectionKeySpecifier = ('edges' | 'pageInfo' | FacebookUserProfileConnectionKeySpecifier)[];
export type FacebookUserProfileConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookUserProfileEdgeKeySpecifier = ('cursor' | 'node' | FacebookUserProfileEdgeKeySpecifier)[];
export type FacebookUserProfileEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookUserProfileFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | FacebookUserProfileFastConnectionKeySpecifier)[];
export type FacebookUserProfileFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FacebookUserProfileFastEdgeKeySpecifier = ('cursor' | 'node' | FacebookUserProfileFastEdgeKeySpecifier)[];
export type FacebookUserProfileFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupKeySpecifier = ('id' | 'name' | 'permissions' | GroupKeySpecifier)[];
export type GroupFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | GroupFastConnectionKeySpecifier)[];
export type GroupFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupFastEdgeKeySpecifier = ('cursor' | 'node' | GroupFastEdgeKeySpecifier)[];
export type GroupFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ImageKeySpecifier = ('height' | 'url' | 'width' | ImageKeySpecifier)[];
export type ImageFieldPolicy = {
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginFacebookUserKeySpecifier = ('refreshToken' | 'success' | 'token' | 'user' | LoginFacebookUserKeySpecifier)[];
export type LoginFacebookUserFieldPolicy = {
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LoginGoogleUserKeySpecifier = ('refreshToken' | 'success' | 'token' | 'user' | LoginGoogleUserKeySpecifier)[];
export type LoginGoogleUserFieldPolicy = {
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionAppSettingKeySpecifier = ('success' | ManageManyToManyCollectionAppSettingKeySpecifier)[];
export type ManageManyToManyCollectionAppSettingFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionFacebookPageKeySpecifier = ('success' | ManageManyToManyCollectionFacebookPageKeySpecifier)[];
export type ManageManyToManyCollectionFacebookPageFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionFacebookPostKeySpecifier = ('success' | ManageManyToManyCollectionFacebookPostKeySpecifier)[];
export type ManageManyToManyCollectionFacebookPostFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionFacebookUserProfileKeySpecifier = ('success' | ManageManyToManyCollectionFacebookUserProfileKeySpecifier)[];
export type ManageManyToManyCollectionFacebookUserProfileFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionGroupKeySpecifier = ('success' | ManageManyToManyCollectionGroupKeySpecifier)[];
export type ManageManyToManyCollectionGroupFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionPermissionKeySpecifier = ('success' | ManageManyToManyCollectionPermissionKeySpecifier)[];
export type ManageManyToManyCollectionPermissionFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionPixieKeySpecifier = ('success' | ManageManyToManyCollectionPixieKeySpecifier)[];
export type ManageManyToManyCollectionPixieFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionPixieImageKeySpecifier = ('success' | ManageManyToManyCollectionPixieImageKeySpecifier)[];
export type ManageManyToManyCollectionPixieImageFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionPixieImageTranslationKeySpecifier = ('success' | ManageManyToManyCollectionPixieImageTranslationKeySpecifier)[];
export type ManageManyToManyCollectionPixieImageTranslationFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionPixieTranslationKeySpecifier = ('success' | ManageManyToManyCollectionPixieTranslationKeySpecifier)[];
export type ManageManyToManyCollectionPixieTranslationFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionScheduleLanguageKeySpecifier = ('success' | ManageManyToManyCollectionScheduleLanguageKeySpecifier)[];
export type ManageManyToManyCollectionScheduleLanguageFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionSchedulePhaseKeySpecifier = ('success' | ManageManyToManyCollectionSchedulePhaseKeySpecifier)[];
export type ManageManyToManyCollectionSchedulePhaseFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ManageManyToManyCollectionUserKeySpecifier = ('success' | ManageManyToManyCollectionUserKeySpecifier)[];
export type ManageManyToManyCollectionUserFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationsKeySpecifier = ('archiveAccount' | 'changePassword' | 'createPixieWithImages' | 'deleteAccount' | 'deleteAppSetting' | 'deleteFacebookPage' | 'deleteFacebookPost' | 'deleteFacebookUserProfile' | 'deleteGroup' | 'deletePageFeedPost' | 'deletePermission' | 'deletePixie' | 'deletePixieImage' | 'deletePixieImageTranslation' | 'deletePixieTranslation' | 'deleteScheduleLanguage' | 'deleteSchedulePhase' | 'deleteUser' | 'loginFacebookUser' | 'loginGoogleUser' | 'manageManyToManyCollectionAppSetting' | 'manageManyToManyCollectionFacebookPage' | 'manageManyToManyCollectionFacebookPost' | 'manageManyToManyCollectionFacebookUserProfile' | 'manageManyToManyCollectionGroup' | 'manageManyToManyCollectionPermission' | 'manageManyToManyCollectionPixie' | 'manageManyToManyCollectionPixieImage' | 'manageManyToManyCollectionPixieImageTranslation' | 'manageManyToManyCollectionPixieTranslation' | 'manageManyToManyCollectionScheduleLanguage' | 'manageManyToManyCollectionSchedulePhase' | 'manageManyToManyCollectionUser' | 'passwordChange' | 'passwordReset' | 'passwordSet' | 'postToPage' | 'refreshToken' | 'register' | 'removeSecondaryEmail' | 'resendActivationEmail' | 'revokeToken' | 'saveAppSetting' | 'saveFacebookPage' | 'saveFacebookPost' | 'saveFacebookUserProfile' | 'saveGroup' | 'saveLongLivedAccessTokenAndUpdatePagesForUser' | 'savePermission' | 'savePixie' | 'savePixieImage' | 'savePixieImageTranslation' | 'savePixieTranslation' | 'saveScheduleLanguage' | 'saveSchedulePhase' | 'saveUser' | 'scheduleABunchOfPosts' | 'schedulePost' | 'sendPasswordResetEmail' | 'sendSecondaryEmailActivation' | 'setPermissionsForGroup' | 'setPermissionsForUser' | 'swapEmails' | 'tokenAuth' | 'updateAccount' | 'updateExistingSlugs' | 'verifyAccount' | 'verifySecondaryEmail' | 'verifyToken' | MutationsKeySpecifier)[];
export type MutationsFieldPolicy = {
	archiveAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	changePassword?: FieldPolicy<any> | FieldReadFunction<any>,
	createPixieWithImages?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAppSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteFacebookPage?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteFacebookPost?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteFacebookUserProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePageFeedPost?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePermission?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePixie?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePixieImage?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePixieImageTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	deletePixieTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteScheduleLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteSchedulePhase?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUser?: FieldPolicy<any> | FieldReadFunction<any>,
	loginFacebookUser?: FieldPolicy<any> | FieldReadFunction<any>,
	loginGoogleUser?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionAppSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionFacebookPage?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionFacebookPost?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionFacebookUserProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionPermission?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionPixie?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionPixieImage?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionPixieImageTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionPixieTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionScheduleLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionSchedulePhase?: FieldPolicy<any> | FieldReadFunction<any>,
	manageManyToManyCollectionUser?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordChange?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordReset?: FieldPolicy<any> | FieldReadFunction<any>,
	passwordSet?: FieldPolicy<any> | FieldReadFunction<any>,
	postToPage?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	register?: FieldPolicy<any> | FieldReadFunction<any>,
	removeSecondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	resendActivationEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	revokeToken?: FieldPolicy<any> | FieldReadFunction<any>,
	saveAppSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	saveFacebookPage?: FieldPolicy<any> | FieldReadFunction<any>,
	saveFacebookPost?: FieldPolicy<any> | FieldReadFunction<any>,
	saveFacebookUserProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	saveGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	saveLongLivedAccessTokenAndUpdatePagesForUser?: FieldPolicy<any> | FieldReadFunction<any>,
	savePermission?: FieldPolicy<any> | FieldReadFunction<any>,
	savePixie?: FieldPolicy<any> | FieldReadFunction<any>,
	savePixieImage?: FieldPolicy<any> | FieldReadFunction<any>,
	savePixieImageTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	savePixieTranslation?: FieldPolicy<any> | FieldReadFunction<any>,
	saveScheduleLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	saveSchedulePhase?: FieldPolicy<any> | FieldReadFunction<any>,
	saveUser?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleABunchOfPosts?: FieldPolicy<any> | FieldReadFunction<any>,
	schedulePost?: FieldPolicy<any> | FieldReadFunction<any>,
	sendPasswordResetEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	sendSecondaryEmailActivation?: FieldPolicy<any> | FieldReadFunction<any>,
	setPermissionsForGroup?: FieldPolicy<any> | FieldReadFunction<any>,
	setPermissionsForUser?: FieldPolicy<any> | FieldReadFunction<any>,
	swapEmails?: FieldPolicy<any> | FieldReadFunction<any>,
	tokenAuth?: FieldPolicy<any> | FieldReadFunction<any>,
	updateAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	updateExistingSlugs?: FieldPolicy<any> | FieldReadFunction<any>,
	verifyAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	verifySecondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	verifyToken?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NodeKeySpecifier = ('id' | NodeKeySpecifier)[];
export type NodeFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ObtainJSONWebTokenKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | 'unarchiving' | 'user' | ObtainJSONWebTokenKeySpecifier)[];
export type ObtainJSONWebTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>,
	unarchiving?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageFeedPostKeySpecifier = ('createdTime' | 'id' | 'mediaType' | 'message' | 'permalinkUrl' | PageFeedPostKeySpecifier)[];
export type PageFeedPostFieldPolicy = {
	createdTime?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	mediaType?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	permalinkUrl?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordChangeKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | PasswordChangeKeySpecifier)[];
export type PasswordChangeFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordResetKeySpecifier = ('errors' | 'success' | PasswordResetKeySpecifier)[];
export type PasswordResetFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PasswordSetKeySpecifier = ('errors' | 'success' | PasswordSetKeySpecifier)[];
export type PasswordSetFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PermissionKeySpecifier = ('codename' | 'id' | 'name' | PermissionKeySpecifier)[];
export type PermissionFieldPolicy = {
	codename?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PermissionFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | PermissionFastConnectionKeySpecifier)[];
export type PermissionFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PermissionFastEdgeKeySpecifier = ('cursor' | 'node' | PermissionFastEdgeKeySpecifier)[];
export type PermissionFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieKeySpecifier = ('content' | 'dateAdded' | 'facebookImage' | 'id' | 'images' | 'isAutoPostingEnabled' | 'primaryLanguage' | 'status' | 'thumbnail' | 'translations' | 'typeOfContent' | PixieKeySpecifier)[];
export type PixieFieldPolicy = {
	content?: FieldPolicy<any> | FieldReadFunction<any>,
	dateAdded?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookImage?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	images?: FieldPolicy<any> | FieldReadFunction<any>,
	isAutoPostingEnabled?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	thumbnail?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>,
	typeOfContent?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | PixieFastConnectionKeySpecifier)[];
export type PixieFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieFastEdgeKeySpecifier = ('cursor' | 'node' | PixieFastEdgeKeySpecifier)[];
export type PixieFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageKeySpecifier = ('height' | 'id' | 'image' | 'pixie' | 'primaryLanguage' | 'translations' | 'width' | PixieImageKeySpecifier)[];
export type PixieImageFieldPolicy = {
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	pixie?: FieldPolicy<any> | FieldReadFunction<any>,
	primaryLanguage?: FieldPolicy<any> | FieldReadFunction<any>,
	translations?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | PixieImageFastConnectionKeySpecifier)[];
export type PixieImageFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageFastEdgeKeySpecifier = ('cursor' | 'node' | PixieImageFastEdgeKeySpecifier)[];
export type PixieImageFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageTranslationKeySpecifier = ('id' | 'language' | 'pixieImage' | 'title' | PixieImageTranslationKeySpecifier)[];
export type PixieImageTranslationFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	pixieImage?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageTranslationFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | PixieImageTranslationFastConnectionKeySpecifier)[];
export type PixieImageTranslationFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieImageTranslationFastEdgeKeySpecifier = ('cursor' | 'node' | PixieImageTranslationFastEdgeKeySpecifier)[];
export type PixieImageTranslationFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieTranslationKeySpecifier = ('autoPostCount' | 'description' | 'id' | 'language' | 'pixie' | 'slug' | 'title' | PixieTranslationKeySpecifier)[];
export type PixieTranslationFieldPolicy = {
	autoPostCount?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	language?: FieldPolicy<any> | FieldReadFunction<any>,
	pixie?: FieldPolicy<any> | FieldReadFunction<any>,
	slug?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieTranslationFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | PixieTranslationFastConnectionKeySpecifier)[];
export type PixieTranslationFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PixieTranslationFastEdgeKeySpecifier = ('cursor' | 'node' | PixieTranslationFastEdgeKeySpecifier)[];
export type PixieTranslationFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PostToPageKeySpecifier = ('id' | 'success' | PostToPageKeySpecifier)[];
export type PostToPageFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('appSettings' | 'checkPassword' | 'facebookPages' | 'facebookPosts' | 'facebookUserProfiles' | 'groupHasPermissions' | 'groups' | 'me' | 'nextJsRevalidationUrl' | 'pageFeedPost' | 'pageFeedPosts' | 'permissions' | 'permissionsLegacy' | 'pixieImageTranslations' | 'pixieImages' | 'pixieTranslations' | 'pixies' | 'randomPixies' | 'readFacebookOgTagsFromUrl' | 'scheduleLanguages' | 'schedulePhases' | 'user' | 'userHasPermissions' | 'users' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	appSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	checkPassword?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookPages?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookPosts?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookUserProfiles?: FieldPolicy<any> | FieldReadFunction<any>,
	groupHasPermissions?: FieldPolicy<any> | FieldReadFunction<any>,
	groups?: FieldPolicy<any> | FieldReadFunction<any>,
	me?: FieldPolicy<any> | FieldReadFunction<any>,
	nextJsRevalidationUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	pageFeedPost?: FieldPolicy<any> | FieldReadFunction<any>,
	pageFeedPosts?: FieldPolicy<any> | FieldReadFunction<any>,
	permissions?: FieldPolicy<any> | FieldReadFunction<any>,
	permissionsLegacy?: FieldPolicy<any> | FieldReadFunction<any>,
	pixieImageTranslations?: FieldPolicy<any> | FieldReadFunction<any>,
	pixieImages?: FieldPolicy<any> | FieldReadFunction<any>,
	pixieTranslations?: FieldPolicy<any> | FieldReadFunction<any>,
	pixies?: FieldPolicy<any> | FieldReadFunction<any>,
	randomPixies?: FieldPolicy<any> | FieldReadFunction<any>,
	readFacebookOgTagsFromUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleLanguages?: FieldPolicy<any> | FieldReadFunction<any>,
	schedulePhases?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	userHasPermissions?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RefreshTokenKeySpecifier = ('errors' | 'payload' | 'refreshToken' | 'success' | 'token' | RefreshTokenKeySpecifier)[];
export type RefreshTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RegisterKeySpecifier = ('errors' | 'refreshToken' | 'success' | 'token' | RegisterKeySpecifier)[];
export type RegisterFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshToken?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RemoveSecondaryEmailKeySpecifier = ('errors' | 'success' | RemoveSecondaryEmailKeySpecifier)[];
export type RemoveSecondaryEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ResendActivationEmailKeySpecifier = ('errors' | 'success' | ResendActivationEmailKeySpecifier)[];
export type ResendActivationEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RevokeTokenKeySpecifier = ('errors' | 'revoked' | 'success' | RevokeTokenKeySpecifier)[];
export type RevokeTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	revoked?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveAppSettingKeySpecifier = ('object' | 'success' | SaveAppSettingKeySpecifier)[];
export type SaveAppSettingFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveFacebookPageKeySpecifier = ('object' | 'success' | SaveFacebookPageKeySpecifier)[];
export type SaveFacebookPageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveFacebookPostKeySpecifier = ('object' | 'success' | SaveFacebookPostKeySpecifier)[];
export type SaveFacebookPostFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveFacebookUserProfileKeySpecifier = ('object' | 'success' | SaveFacebookUserProfileKeySpecifier)[];
export type SaveFacebookUserProfileFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveGroupKeySpecifier = ('object' | 'success' | SaveGroupKeySpecifier)[];
export type SaveGroupFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveLongLivedAccessTokenAndUpdatePagesForUserKeySpecifier = ('success' | SaveLongLivedAccessTokenAndUpdatePagesForUserKeySpecifier)[];
export type SaveLongLivedAccessTokenAndUpdatePagesForUserFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePermissionKeySpecifier = ('object' | 'success' | SavePermissionKeySpecifier)[];
export type SavePermissionFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePixieKeySpecifier = ('object' | 'success' | SavePixieKeySpecifier)[];
export type SavePixieFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePixieImageKeySpecifier = ('object' | 'success' | SavePixieImageKeySpecifier)[];
export type SavePixieImageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePixieImageTranslationKeySpecifier = ('object' | 'success' | SavePixieImageTranslationKeySpecifier)[];
export type SavePixieImageTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SavePixieTranslationKeySpecifier = ('object' | 'success' | SavePixieTranslationKeySpecifier)[];
export type SavePixieTranslationFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveScheduleLanguageKeySpecifier = ('object' | 'success' | SaveScheduleLanguageKeySpecifier)[];
export type SaveScheduleLanguageFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveSchedulePhaseKeySpecifier = ('object' | 'success' | SaveSchedulePhaseKeySpecifier)[];
export type SaveSchedulePhaseFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SaveUserKeySpecifier = ('object' | 'success' | SaveUserKeySpecifier)[];
export type SaveUserFieldPolicy = {
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleABunchOfPostsKeySpecifier = ('posts' | 'success' | ScheduleABunchOfPostsKeySpecifier)[];
export type ScheduleABunchOfPostsFieldPolicy = {
	posts?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleLanguageKeySpecifier = ('code' | 'facebookPages' | 'id' | 'name' | 'sequenceNo' | ScheduleLanguageKeySpecifier)[];
export type ScheduleLanguageFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookPages?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	sequenceNo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleLanguageFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | ScheduleLanguageFastConnectionKeySpecifier)[];
export type ScheduleLanguageFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduleLanguageFastEdgeKeySpecifier = ('cursor' | 'node' | ScheduleLanguageFastEdgeKeySpecifier)[];
export type ScheduleLanguageFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePhaseKeySpecifier = ('facebookPages' | 'id' | 'schedulePhases' | 'scheduleTime' | 'sequenceNo' | SchedulePhaseKeySpecifier)[];
export type SchedulePhaseFieldPolicy = {
	facebookPages?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	schedulePhases?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleTime?: FieldPolicy<any> | FieldReadFunction<any>,
	sequenceNo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePhaseConnectionKeySpecifier = ('edges' | 'pageInfo' | SchedulePhaseConnectionKeySpecifier)[];
export type SchedulePhaseConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePhaseEdgeKeySpecifier = ('cursor' | 'node' | SchedulePhaseEdgeKeySpecifier)[];
export type SchedulePhaseEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePhaseFastConnectionKeySpecifier = ('currentPage' | 'edges' | 'pageInfo' | 'pages' | 'totalCount' | SchedulePhaseFastConnectionKeySpecifier)[];
export type SchedulePhaseFastConnectionFieldPolicy = {
	currentPage?: FieldPolicy<any> | FieldReadFunction<any>,
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	pages?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePhaseFastEdgeKeySpecifier = ('cursor' | 'node' | SchedulePhaseFastEdgeKeySpecifier)[];
export type SchedulePhaseFastEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SchedulePostKeySpecifier = ('post' | 'success' | SchedulePostKeySpecifier)[];
export type SchedulePostFieldPolicy = {
	post?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendPasswordResetEmailKeySpecifier = ('errors' | 'success' | SendPasswordResetEmailKeySpecifier)[];
export type SendPasswordResetEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SendSecondaryEmailActivationKeySpecifier = ('errors' | 'success' | SendSecondaryEmailActivationKeySpecifier)[];
export type SendSecondaryEmailActivationFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetPermissionsForGroupKeySpecifier = ('success' | SetPermissionsForGroupKeySpecifier)[];
export type SetPermissionsForGroupFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetPermissionsForUserKeySpecifier = ('success' | SetPermissionsForUserKeySpecifier)[];
export type SetPermissionsForUserFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SwapEmailsKeySpecifier = ('errors' | 'success' | SwapEmailsKeySpecifier)[];
export type SwapEmailsFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateAccountKeySpecifier = ('errors' | 'success' | UpdateAccountKeySpecifier)[];
export type UpdateAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UpdateExistingSlugsKeySpecifier = ('success' | UpdateExistingSlugsKeySpecifier)[];
export type UpdateExistingSlugsFieldPolicy = {
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeKeySpecifier = ('archived' | 'dateJoined' | 'email' | 'facebookLoginId' | 'facebookUserProfiles' | 'firstName' | 'googleLoginId' | 'id' | 'isActive' | 'isStaff' | 'lastLogin' | 'lastName' | 'name' | 'nickname' | 'pk' | 'secondaryEmail' | 'username' | 'verified' | UserNodeKeySpecifier)[];
export type UserNodeFieldPolicy = {
	archived?: FieldPolicy<any> | FieldReadFunction<any>,
	dateJoined?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookLoginId?: FieldPolicy<any> | FieldReadFunction<any>,
	facebookUserProfiles?: FieldPolicy<any> | FieldReadFunction<any>,
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	googleLoginId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isActive?: FieldPolicy<any> | FieldReadFunction<any>,
	isStaff?: FieldPolicy<any> | FieldReadFunction<any>,
	lastLogin?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	nickname?: FieldPolicy<any> | FieldReadFunction<any>,
	pk?: FieldPolicy<any> | FieldReadFunction<any>,
	secondaryEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	username?: FieldPolicy<any> | FieldReadFunction<any>,
	verified?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeConnectionKeySpecifier = ('edges' | 'pageInfo' | UserNodeConnectionKeySpecifier)[];
export type UserNodeConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserNodeEdgeKeySpecifier = ('cursor' | 'node' | UserNodeEdgeKeySpecifier)[];
export type UserNodeEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifyAccountKeySpecifier = ('errors' | 'success' | VerifyAccountKeySpecifier)[];
export type VerifyAccountFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifySecondaryEmailKeySpecifier = ('errors' | 'success' | VerifySecondaryEmailKeySpecifier)[];
export type VerifySecondaryEmailFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type VerifyTokenKeySpecifier = ('errors' | 'payload' | 'success' | VerifyTokenKeySpecifier)[];
export type VerifyTokenFieldPolicy = {
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>,
	success?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingKeySpecifier | (() => undefined | AppSettingKeySpecifier),
		fields?: AppSettingFieldPolicy,
	},
	AppSettingFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingFastConnectionKeySpecifier | (() => undefined | AppSettingFastConnectionKeySpecifier),
		fields?: AppSettingFastConnectionFieldPolicy,
	},
	AppSettingFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppSettingFastEdgeKeySpecifier | (() => undefined | AppSettingFastEdgeKeySpecifier),
		fields?: AppSettingFastEdgeFieldPolicy,
	},
	ArchiveAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArchiveAccountKeySpecifier | (() => undefined | ArchiveAccountKeySpecifier),
		fields?: ArchiveAccountFieldPolicy,
	},
	ChangePassword?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChangePasswordKeySpecifier | (() => undefined | ChangePasswordKeySpecifier),
		fields?: ChangePasswordFieldPolicy,
	},
	CreatePixieWithImages?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreatePixieWithImagesKeySpecifier | (() => undefined | CreatePixieWithImagesKeySpecifier),
		fields?: CreatePixieWithImagesFieldPolicy,
	},
	DeleteAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteAccountKeySpecifier | (() => undefined | DeleteAccountKeySpecifier),
		fields?: DeleteAccountFieldPolicy,
	},
	DeleteAppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteAppSettingKeySpecifier | (() => undefined | DeleteAppSettingKeySpecifier),
		fields?: DeleteAppSettingFieldPolicy,
	},
	DeleteFacebookPage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteFacebookPageKeySpecifier | (() => undefined | DeleteFacebookPageKeySpecifier),
		fields?: DeleteFacebookPageFieldPolicy,
	},
	DeleteFacebookPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteFacebookPostKeySpecifier | (() => undefined | DeleteFacebookPostKeySpecifier),
		fields?: DeleteFacebookPostFieldPolicy,
	},
	DeleteFacebookUserProfile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteFacebookUserProfileKeySpecifier | (() => undefined | DeleteFacebookUserProfileKeySpecifier),
		fields?: DeleteFacebookUserProfileFieldPolicy,
	},
	DeleteGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteGroupKeySpecifier | (() => undefined | DeleteGroupKeySpecifier),
		fields?: DeleteGroupFieldPolicy,
	},
	DeletePageFeedPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePageFeedPostKeySpecifier | (() => undefined | DeletePageFeedPostKeySpecifier),
		fields?: DeletePageFeedPostFieldPolicy,
	},
	DeletePermission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePermissionKeySpecifier | (() => undefined | DeletePermissionKeySpecifier),
		fields?: DeletePermissionFieldPolicy,
	},
	DeletePixie?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePixieKeySpecifier | (() => undefined | DeletePixieKeySpecifier),
		fields?: DeletePixieFieldPolicy,
	},
	DeletePixieImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePixieImageKeySpecifier | (() => undefined | DeletePixieImageKeySpecifier),
		fields?: DeletePixieImageFieldPolicy,
	},
	DeletePixieImageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePixieImageTranslationKeySpecifier | (() => undefined | DeletePixieImageTranslationKeySpecifier),
		fields?: DeletePixieImageTranslationFieldPolicy,
	},
	DeletePixieTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeletePixieTranslationKeySpecifier | (() => undefined | DeletePixieTranslationKeySpecifier),
		fields?: DeletePixieTranslationFieldPolicy,
	},
	DeleteScheduleLanguage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteScheduleLanguageKeySpecifier | (() => undefined | DeleteScheduleLanguageKeySpecifier),
		fields?: DeleteScheduleLanguageFieldPolicy,
	},
	DeleteSchedulePhase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteSchedulePhaseKeySpecifier | (() => undefined | DeleteSchedulePhaseKeySpecifier),
		fields?: DeleteSchedulePhaseFieldPolicy,
	},
	DeleteUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeleteUserKeySpecifier | (() => undefined | DeleteUserKeySpecifier),
		fields?: DeleteUserFieldPolicy,
	},
	FacebookMetaTagsType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookMetaTagsTypeKeySpecifier | (() => undefined | FacebookMetaTagsTypeKeySpecifier),
		fields?: FacebookMetaTagsTypeFieldPolicy,
	},
	FacebookPage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPageKeySpecifier | (() => undefined | FacebookPageKeySpecifier),
		fields?: FacebookPageFieldPolicy,
	},
	FacebookPageConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPageConnectionKeySpecifier | (() => undefined | FacebookPageConnectionKeySpecifier),
		fields?: FacebookPageConnectionFieldPolicy,
	},
	FacebookPageEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPageEdgeKeySpecifier | (() => undefined | FacebookPageEdgeKeySpecifier),
		fields?: FacebookPageEdgeFieldPolicy,
	},
	FacebookPageFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPageFastConnectionKeySpecifier | (() => undefined | FacebookPageFastConnectionKeySpecifier),
		fields?: FacebookPageFastConnectionFieldPolicy,
	},
	FacebookPageFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPageFastEdgeKeySpecifier | (() => undefined | FacebookPageFastEdgeKeySpecifier),
		fields?: FacebookPageFastEdgeFieldPolicy,
	},
	FacebookPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPostKeySpecifier | (() => undefined | FacebookPostKeySpecifier),
		fields?: FacebookPostFieldPolicy,
	},
	FacebookPostConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPostConnectionKeySpecifier | (() => undefined | FacebookPostConnectionKeySpecifier),
		fields?: FacebookPostConnectionFieldPolicy,
	},
	FacebookPostEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPostEdgeKeySpecifier | (() => undefined | FacebookPostEdgeKeySpecifier),
		fields?: FacebookPostEdgeFieldPolicy,
	},
	FacebookPostFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPostFastConnectionKeySpecifier | (() => undefined | FacebookPostFastConnectionKeySpecifier),
		fields?: FacebookPostFastConnectionFieldPolicy,
	},
	FacebookPostFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookPostFastEdgeKeySpecifier | (() => undefined | FacebookPostFastEdgeKeySpecifier),
		fields?: FacebookPostFastEdgeFieldPolicy,
	},
	FacebookUserProfile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookUserProfileKeySpecifier | (() => undefined | FacebookUserProfileKeySpecifier),
		fields?: FacebookUserProfileFieldPolicy,
	},
	FacebookUserProfileConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookUserProfileConnectionKeySpecifier | (() => undefined | FacebookUserProfileConnectionKeySpecifier),
		fields?: FacebookUserProfileConnectionFieldPolicy,
	},
	FacebookUserProfileEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookUserProfileEdgeKeySpecifier | (() => undefined | FacebookUserProfileEdgeKeySpecifier),
		fields?: FacebookUserProfileEdgeFieldPolicy,
	},
	FacebookUserProfileFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookUserProfileFastConnectionKeySpecifier | (() => undefined | FacebookUserProfileFastConnectionKeySpecifier),
		fields?: FacebookUserProfileFastConnectionFieldPolicy,
	},
	FacebookUserProfileFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FacebookUserProfileFastEdgeKeySpecifier | (() => undefined | FacebookUserProfileFastEdgeKeySpecifier),
		fields?: FacebookUserProfileFastEdgeFieldPolicy,
	},
	Group?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupKeySpecifier | (() => undefined | GroupKeySpecifier),
		fields?: GroupFieldPolicy,
	},
	GroupFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupFastConnectionKeySpecifier | (() => undefined | GroupFastConnectionKeySpecifier),
		fields?: GroupFastConnectionFieldPolicy,
	},
	GroupFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupFastEdgeKeySpecifier | (() => undefined | GroupFastEdgeKeySpecifier),
		fields?: GroupFastEdgeFieldPolicy,
	},
	Image?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ImageKeySpecifier | (() => undefined | ImageKeySpecifier),
		fields?: ImageFieldPolicy,
	},
	LoginFacebookUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginFacebookUserKeySpecifier | (() => undefined | LoginFacebookUserKeySpecifier),
		fields?: LoginFacebookUserFieldPolicy,
	},
	LoginGoogleUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LoginGoogleUserKeySpecifier | (() => undefined | LoginGoogleUserKeySpecifier),
		fields?: LoginGoogleUserFieldPolicy,
	},
	ManageManyToManyCollectionAppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionAppSettingKeySpecifier | (() => undefined | ManageManyToManyCollectionAppSettingKeySpecifier),
		fields?: ManageManyToManyCollectionAppSettingFieldPolicy,
	},
	ManageManyToManyCollectionFacebookPage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionFacebookPageKeySpecifier | (() => undefined | ManageManyToManyCollectionFacebookPageKeySpecifier),
		fields?: ManageManyToManyCollectionFacebookPageFieldPolicy,
	},
	ManageManyToManyCollectionFacebookPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionFacebookPostKeySpecifier | (() => undefined | ManageManyToManyCollectionFacebookPostKeySpecifier),
		fields?: ManageManyToManyCollectionFacebookPostFieldPolicy,
	},
	ManageManyToManyCollectionFacebookUserProfile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionFacebookUserProfileKeySpecifier | (() => undefined | ManageManyToManyCollectionFacebookUserProfileKeySpecifier),
		fields?: ManageManyToManyCollectionFacebookUserProfileFieldPolicy,
	},
	ManageManyToManyCollectionGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionGroupKeySpecifier | (() => undefined | ManageManyToManyCollectionGroupKeySpecifier),
		fields?: ManageManyToManyCollectionGroupFieldPolicy,
	},
	ManageManyToManyCollectionPermission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionPermissionKeySpecifier | (() => undefined | ManageManyToManyCollectionPermissionKeySpecifier),
		fields?: ManageManyToManyCollectionPermissionFieldPolicy,
	},
	ManageManyToManyCollectionPixie?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionPixieKeySpecifier | (() => undefined | ManageManyToManyCollectionPixieKeySpecifier),
		fields?: ManageManyToManyCollectionPixieFieldPolicy,
	},
	ManageManyToManyCollectionPixieImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionPixieImageKeySpecifier | (() => undefined | ManageManyToManyCollectionPixieImageKeySpecifier),
		fields?: ManageManyToManyCollectionPixieImageFieldPolicy,
	},
	ManageManyToManyCollectionPixieImageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionPixieImageTranslationKeySpecifier | (() => undefined | ManageManyToManyCollectionPixieImageTranslationKeySpecifier),
		fields?: ManageManyToManyCollectionPixieImageTranslationFieldPolicy,
	},
	ManageManyToManyCollectionPixieTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionPixieTranslationKeySpecifier | (() => undefined | ManageManyToManyCollectionPixieTranslationKeySpecifier),
		fields?: ManageManyToManyCollectionPixieTranslationFieldPolicy,
	},
	ManageManyToManyCollectionScheduleLanguage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionScheduleLanguageKeySpecifier | (() => undefined | ManageManyToManyCollectionScheduleLanguageKeySpecifier),
		fields?: ManageManyToManyCollectionScheduleLanguageFieldPolicy,
	},
	ManageManyToManyCollectionSchedulePhase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionSchedulePhaseKeySpecifier | (() => undefined | ManageManyToManyCollectionSchedulePhaseKeySpecifier),
		fields?: ManageManyToManyCollectionSchedulePhaseFieldPolicy,
	},
	ManageManyToManyCollectionUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ManageManyToManyCollectionUserKeySpecifier | (() => undefined | ManageManyToManyCollectionUserKeySpecifier),
		fields?: ManageManyToManyCollectionUserFieldPolicy,
	},
	Mutations?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationsKeySpecifier | (() => undefined | MutationsKeySpecifier),
		fields?: MutationsFieldPolicy,
	},
	Node?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NodeKeySpecifier | (() => undefined | NodeKeySpecifier),
		fields?: NodeFieldPolicy,
	},
	ObtainJSONWebToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ObtainJSONWebTokenKeySpecifier | (() => undefined | ObtainJSONWebTokenKeySpecifier),
		fields?: ObtainJSONWebTokenFieldPolicy,
	},
	PageFeedPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageFeedPostKeySpecifier | (() => undefined | PageFeedPostKeySpecifier),
		fields?: PageFeedPostFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PasswordChange?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordChangeKeySpecifier | (() => undefined | PasswordChangeKeySpecifier),
		fields?: PasswordChangeFieldPolicy,
	},
	PasswordReset?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordResetKeySpecifier | (() => undefined | PasswordResetKeySpecifier),
		fields?: PasswordResetFieldPolicy,
	},
	PasswordSet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PasswordSetKeySpecifier | (() => undefined | PasswordSetKeySpecifier),
		fields?: PasswordSetFieldPolicy,
	},
	Permission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier),
		fields?: PermissionFieldPolicy,
	},
	PermissionFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PermissionFastConnectionKeySpecifier | (() => undefined | PermissionFastConnectionKeySpecifier),
		fields?: PermissionFastConnectionFieldPolicy,
	},
	PermissionFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PermissionFastEdgeKeySpecifier | (() => undefined | PermissionFastEdgeKeySpecifier),
		fields?: PermissionFastEdgeFieldPolicy,
	},
	Pixie?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieKeySpecifier | (() => undefined | PixieKeySpecifier),
		fields?: PixieFieldPolicy,
	},
	PixieFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieFastConnectionKeySpecifier | (() => undefined | PixieFastConnectionKeySpecifier),
		fields?: PixieFastConnectionFieldPolicy,
	},
	PixieFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieFastEdgeKeySpecifier | (() => undefined | PixieFastEdgeKeySpecifier),
		fields?: PixieFastEdgeFieldPolicy,
	},
	PixieImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageKeySpecifier | (() => undefined | PixieImageKeySpecifier),
		fields?: PixieImageFieldPolicy,
	},
	PixieImageFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageFastConnectionKeySpecifier | (() => undefined | PixieImageFastConnectionKeySpecifier),
		fields?: PixieImageFastConnectionFieldPolicy,
	},
	PixieImageFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageFastEdgeKeySpecifier | (() => undefined | PixieImageFastEdgeKeySpecifier),
		fields?: PixieImageFastEdgeFieldPolicy,
	},
	PixieImageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageTranslationKeySpecifier | (() => undefined | PixieImageTranslationKeySpecifier),
		fields?: PixieImageTranslationFieldPolicy,
	},
	PixieImageTranslationFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageTranslationFastConnectionKeySpecifier | (() => undefined | PixieImageTranslationFastConnectionKeySpecifier),
		fields?: PixieImageTranslationFastConnectionFieldPolicy,
	},
	PixieImageTranslationFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieImageTranslationFastEdgeKeySpecifier | (() => undefined | PixieImageTranslationFastEdgeKeySpecifier),
		fields?: PixieImageTranslationFastEdgeFieldPolicy,
	},
	PixieTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieTranslationKeySpecifier | (() => undefined | PixieTranslationKeySpecifier),
		fields?: PixieTranslationFieldPolicy,
	},
	PixieTranslationFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieTranslationFastConnectionKeySpecifier | (() => undefined | PixieTranslationFastConnectionKeySpecifier),
		fields?: PixieTranslationFastConnectionFieldPolicy,
	},
	PixieTranslationFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PixieTranslationFastEdgeKeySpecifier | (() => undefined | PixieTranslationFastEdgeKeySpecifier),
		fields?: PixieTranslationFastEdgeFieldPolicy,
	},
	PostToPage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PostToPageKeySpecifier | (() => undefined | PostToPageKeySpecifier),
		fields?: PostToPageFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	RefreshToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RefreshTokenKeySpecifier | (() => undefined | RefreshTokenKeySpecifier),
		fields?: RefreshTokenFieldPolicy,
	},
	Register?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RegisterKeySpecifier | (() => undefined | RegisterKeySpecifier),
		fields?: RegisterFieldPolicy,
	},
	RemoveSecondaryEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RemoveSecondaryEmailKeySpecifier | (() => undefined | RemoveSecondaryEmailKeySpecifier),
		fields?: RemoveSecondaryEmailFieldPolicy,
	},
	ResendActivationEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ResendActivationEmailKeySpecifier | (() => undefined | ResendActivationEmailKeySpecifier),
		fields?: ResendActivationEmailFieldPolicy,
	},
	RevokeToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RevokeTokenKeySpecifier | (() => undefined | RevokeTokenKeySpecifier),
		fields?: RevokeTokenFieldPolicy,
	},
	SaveAppSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveAppSettingKeySpecifier | (() => undefined | SaveAppSettingKeySpecifier),
		fields?: SaveAppSettingFieldPolicy,
	},
	SaveFacebookPage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveFacebookPageKeySpecifier | (() => undefined | SaveFacebookPageKeySpecifier),
		fields?: SaveFacebookPageFieldPolicy,
	},
	SaveFacebookPost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveFacebookPostKeySpecifier | (() => undefined | SaveFacebookPostKeySpecifier),
		fields?: SaveFacebookPostFieldPolicy,
	},
	SaveFacebookUserProfile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveFacebookUserProfileKeySpecifier | (() => undefined | SaveFacebookUserProfileKeySpecifier),
		fields?: SaveFacebookUserProfileFieldPolicy,
	},
	SaveGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveGroupKeySpecifier | (() => undefined | SaveGroupKeySpecifier),
		fields?: SaveGroupFieldPolicy,
	},
	SaveLongLivedAccessTokenAndUpdatePagesForUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveLongLivedAccessTokenAndUpdatePagesForUserKeySpecifier | (() => undefined | SaveLongLivedAccessTokenAndUpdatePagesForUserKeySpecifier),
		fields?: SaveLongLivedAccessTokenAndUpdatePagesForUserFieldPolicy,
	},
	SavePermission?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePermissionKeySpecifier | (() => undefined | SavePermissionKeySpecifier),
		fields?: SavePermissionFieldPolicy,
	},
	SavePixie?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePixieKeySpecifier | (() => undefined | SavePixieKeySpecifier),
		fields?: SavePixieFieldPolicy,
	},
	SavePixieImage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePixieImageKeySpecifier | (() => undefined | SavePixieImageKeySpecifier),
		fields?: SavePixieImageFieldPolicy,
	},
	SavePixieImageTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePixieImageTranslationKeySpecifier | (() => undefined | SavePixieImageTranslationKeySpecifier),
		fields?: SavePixieImageTranslationFieldPolicy,
	},
	SavePixieTranslation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SavePixieTranslationKeySpecifier | (() => undefined | SavePixieTranslationKeySpecifier),
		fields?: SavePixieTranslationFieldPolicy,
	},
	SaveScheduleLanguage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveScheduleLanguageKeySpecifier | (() => undefined | SaveScheduleLanguageKeySpecifier),
		fields?: SaveScheduleLanguageFieldPolicy,
	},
	SaveSchedulePhase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveSchedulePhaseKeySpecifier | (() => undefined | SaveSchedulePhaseKeySpecifier),
		fields?: SaveSchedulePhaseFieldPolicy,
	},
	SaveUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SaveUserKeySpecifier | (() => undefined | SaveUserKeySpecifier),
		fields?: SaveUserFieldPolicy,
	},
	ScheduleABunchOfPosts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleABunchOfPostsKeySpecifier | (() => undefined | ScheduleABunchOfPostsKeySpecifier),
		fields?: ScheduleABunchOfPostsFieldPolicy,
	},
	ScheduleLanguage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleLanguageKeySpecifier | (() => undefined | ScheduleLanguageKeySpecifier),
		fields?: ScheduleLanguageFieldPolicy,
	},
	ScheduleLanguageFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleLanguageFastConnectionKeySpecifier | (() => undefined | ScheduleLanguageFastConnectionKeySpecifier),
		fields?: ScheduleLanguageFastConnectionFieldPolicy,
	},
	ScheduleLanguageFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduleLanguageFastEdgeKeySpecifier | (() => undefined | ScheduleLanguageFastEdgeKeySpecifier),
		fields?: ScheduleLanguageFastEdgeFieldPolicy,
	},
	SchedulePhase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePhaseKeySpecifier | (() => undefined | SchedulePhaseKeySpecifier),
		fields?: SchedulePhaseFieldPolicy,
	},
	SchedulePhaseConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePhaseConnectionKeySpecifier | (() => undefined | SchedulePhaseConnectionKeySpecifier),
		fields?: SchedulePhaseConnectionFieldPolicy,
	},
	SchedulePhaseEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePhaseEdgeKeySpecifier | (() => undefined | SchedulePhaseEdgeKeySpecifier),
		fields?: SchedulePhaseEdgeFieldPolicy,
	},
	SchedulePhaseFastConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePhaseFastConnectionKeySpecifier | (() => undefined | SchedulePhaseFastConnectionKeySpecifier),
		fields?: SchedulePhaseFastConnectionFieldPolicy,
	},
	SchedulePhaseFastEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePhaseFastEdgeKeySpecifier | (() => undefined | SchedulePhaseFastEdgeKeySpecifier),
		fields?: SchedulePhaseFastEdgeFieldPolicy,
	},
	SchedulePost?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SchedulePostKeySpecifier | (() => undefined | SchedulePostKeySpecifier),
		fields?: SchedulePostFieldPolicy,
	},
	SendPasswordResetEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendPasswordResetEmailKeySpecifier | (() => undefined | SendPasswordResetEmailKeySpecifier),
		fields?: SendPasswordResetEmailFieldPolicy,
	},
	SendSecondaryEmailActivation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SendSecondaryEmailActivationKeySpecifier | (() => undefined | SendSecondaryEmailActivationKeySpecifier),
		fields?: SendSecondaryEmailActivationFieldPolicy,
	},
	SetPermissionsForGroup?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetPermissionsForGroupKeySpecifier | (() => undefined | SetPermissionsForGroupKeySpecifier),
		fields?: SetPermissionsForGroupFieldPolicy,
	},
	SetPermissionsForUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetPermissionsForUserKeySpecifier | (() => undefined | SetPermissionsForUserKeySpecifier),
		fields?: SetPermissionsForUserFieldPolicy,
	},
	SwapEmails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SwapEmailsKeySpecifier | (() => undefined | SwapEmailsKeySpecifier),
		fields?: SwapEmailsFieldPolicy,
	},
	UpdateAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateAccountKeySpecifier | (() => undefined | UpdateAccountKeySpecifier),
		fields?: UpdateAccountFieldPolicy,
	},
	UpdateExistingSlugs?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UpdateExistingSlugsKeySpecifier | (() => undefined | UpdateExistingSlugsKeySpecifier),
		fields?: UpdateExistingSlugsFieldPolicy,
	},
	UserNode?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeKeySpecifier | (() => undefined | UserNodeKeySpecifier),
		fields?: UserNodeFieldPolicy,
	},
	UserNodeConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeConnectionKeySpecifier | (() => undefined | UserNodeConnectionKeySpecifier),
		fields?: UserNodeConnectionFieldPolicy,
	},
	UserNodeEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserNodeEdgeKeySpecifier | (() => undefined | UserNodeEdgeKeySpecifier),
		fields?: UserNodeEdgeFieldPolicy,
	},
	VerifyAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifyAccountKeySpecifier | (() => undefined | VerifyAccountKeySpecifier),
		fields?: VerifyAccountFieldPolicy,
	},
	VerifySecondaryEmail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifySecondaryEmailKeySpecifier | (() => undefined | VerifySecondaryEmailKeySpecifier),
		fields?: VerifySecondaryEmailFieldPolicy,
	},
	VerifyToken?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | VerifyTokenKeySpecifier | (() => undefined | VerifyTokenKeySpecifier),
		fields?: VerifyTokenFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;